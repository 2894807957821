import { Button } from "@mui/material";
import { useState } from "react";
import { ImageSelectionDialog } from "./ImageSelectionDialog";

export const ImageSelectorButton = (props) => {
  const [ imagesDialogOpen, setImagesDialogOpen ] = useState(false);
  return (
    <>
      <Button onClick={() => { setImagesDialogOpen(true); }}>
        {props.children}
      </Button>
      <ImageSelectionDialog dialogOpen={imagesDialogOpen} setDialogOpen={setImagesDialogOpen} onSelect={props.onSelect}/>
    </>
  )
}
