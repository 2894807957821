import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"

export const MultipleChoiceField = (props) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup row value={props.selectedKey} onChange={event => props.onChange(event.target.value)}>
        {props.choices.map(choice => (
          <FormControlLabel key={choice.key} value={choice.key} control={<Radio />} label={choice.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
