import * as THREE from "three";
import Vertex from "./Vertex";

class Face {
    constructor(vertex1, vertex2, vertex3) {
        this.vertex1 = vertex1;
        this.vertex2 = vertex2;
        this.vertex3 = vertex3;
    }

    get_mirror_on_yz_plane() {
        return new Face(this.vertex1.get_mirror_on_yz_plane(), this.vertex2.get_mirror_on_yz_plane(), this.vertex3.get_mirror_on_yz_plane());
    }
    
    get_projection_on_yz_plane() {
        return new Face(this.vertex1.get_projection_on_yz_plane(), this.vertex2.get_projection_on_yz_plane(), this.vertex3.get_projection_on_yz_plane());
    }
    
    reverse() {
        [this.vertex1, this.vertex3] = [this.vertex3, this.vertex1];
        return this;
    }

    toTriangle() {
        return new THREE.Triangle(this.vertex1.toVector3(), this.vertex2.toVector3(), this.vertex3.toVector3())
    }

    getNormal() {
        return new Vertex((-this.vertex2.z+this.vertex1.z)*(this.vertex3.y-this.vertex1.y) + (this.vertex2.y-this.vertex1.y)*(this.vertex3.z-this.vertex1.z),
                          (this.vertex2.z-this.vertex1.z)*(this.vertex3.x-this.vertex1.x) + (-this.vertex2.x-this.vertex1.x)*(this.vertex3.z-this.vertex1.z),
                          (-this.vertex2.y+this.vertex1.y)*(this.vertex3.x-this.vertex1.x) + (this.vertex2.x-this.vertex1.x)*(this.vertex3.y-this.vertex1.y)
                         );
    }

    toFloat32Array() {
        return [].concat(this.vertex1.toFloat32Array())
                 .concat(this.vertex2.toFloat32Array())
                 .concat(this.vertex3.toFloat32Array());
    }
}

export default Face;
