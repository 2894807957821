const ensurePositive = (value) => {
  if (value <= 0) {
    throw new Error(`Value ${value} is not positive.`);
  }
}

export const BuildSettings = (horizontalPitch, verticalPitch) => {
  horizontalPitch = Number(horizontalPitch);
  verticalPitch = Number(verticalPitch);
  ensurePositive(horizontalPitch);
  ensurePositive(verticalPitch);

  return Object.freeze({
    pitch: {
      horizontalPitch,
      verticalPitch,
    }
  });
}
