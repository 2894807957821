import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { Queue } from "buckets-js"

export const solidSlice = createSlice({
  name: "solid",
  initialState: {
    layers: [],
    dimensions: {
      width: 100,
      height: 75,
    },
    reverseSide: "flat",
  },
  reducers: {
    resetSolid: (state, action) => {
      if (action.payload && action.payload.solidSpec) {
        const solidSpec = JSON.parse(JSON.stringify(action.payload.solidSpec));
        state.layers = solidSpec.layers;
        state.dimensions = solidSpec.dimensions;
      }
      else {
        state.layers = [];
        state.width = 100;
        state.height = 75;
      }
    },
    addInitialLayer: (state, action) => {
      const layerToAdd = JSON.parse(JSON.stringify(action.payload.layer));
      if (layerToAdd.parentLayerId || state.layers.length) {
        throw new Error("Cannot add an initial layer because some layers already exist.")
      }
      state.layers.push(layerToAdd);
    },
    addChildLayer: (state, action) => {
      const layerToAdd = JSON.parse(JSON.stringify(action.payload.layer));
      const parentLayerId = layerToAdd.parentLayerRef.id;

      if (!state.layers.find(layer => layer.id === parentLayerId)) {
        throw new Error(`Parent layer with id ${parentLayerId} does not exist`);
      }
      if (state.layers.find(layer => layer.parentLayerId === parentLayerId && layer.areaId === layerToAdd.parentLayerRef.areaId)) {
        throw new Error("Another layer already occupies the same area under the same parent");
      }
      state.layers.push(layerToAdd);
    },
    addSurroundingLayer: (state, action) => {
      const layerToAdd = JSON.parse(JSON.stringify(action.payload.layer));
      const childLayerId = action.payload.childLayerId;
      const childLayer = state.layers.find(layer => layer.id === childLayerId);
      if (!childLayer) {
        throw new Error(`Child layer with id ${childLayerId} does not exist`);
      }
      const areaId = action.payload.areaId;
      layerToAdd.parentLayerRef = childLayer.parentLayerRef;
      childLayer.parentLayerRef = { id: layerToAdd.id, areaId: areaId };
      state.layers.push(layerToAdd);
    },
    replaceLayer: (state, action) => {
      const layerId = action.payload.layer.id;
      const layerIndex = state.layers.findIndex(layer => layer.id === layerId);
      if (layerIndex === -1) {
        throw new Error(`Replace: Layer with id ${layerId} not found`);
      }
      state.layers[layerIndex] = JSON.parse(JSON.stringify(action.payload.layer));
    },
    removeLayer: (state, action) => {
      // Remove layer and all children
      const topLayerId = action.payload.layerId;
      const traversalQueue = new Queue();
      traversalQueue.enqueue(topLayerId);
      while (!traversalQueue.isEmpty()) {
        const layerId = traversalQueue.dequeue();
        const layerIndex = state.layers.findIndex(layer => layer.id === layerId);
        if (layerIndex === -1) {
          throw new Error(`Remove: Layer with id ${layerId} not found`);
        }
        state.layers.splice(layerIndex, 1);
        state.layers.filter(layer => layer.parentLayerRef?.id === layerId).forEach(layer => traversalQueue.enqueue(layer.id));
      }
    },
    setSolidProperties: (state, action) => {
      if (action.payload.dimensions) {
        state.dimensions.width = Number(action.payload.dimensions.width);
        state.dimensions.height = Number(action.payload.dimensions.height);
      }
      if (action.payload.reverseSide) {
        state.reverseSide = action.payload.reverseSide;
      }
    }
  },
});


const selectSolid = state => state.solid;

export const useSolid = () => {
  return useSelector(selectSolid);
}

export const { resetSolid, replaceLayer, removeLayer, setSolidProperties } = solidSlice.actions;

export const addSurroundingLayer = (layer, areaId, layerToSurround, dispatch) => {
  if (!layerToSurround) {
    dispatch(solidSlice.actions.addInitialLayer(layer));
  } else {
    dispatch(solidSlice.actions.addSurroundingLayer({...layer, areaId: areaId, childLayerId: layerToSurround.id}));
  }
}

export const addChildLayer = (layerSpec, parentLayerId, parentAreaId, dispatch) => {
  if (!parentLayerId) {
    dispatch(solidSlice.actions.addInitialLayer({layer: layerSpec}));
  } else {
    dispatch(solidSlice.actions.addChildLayer({layer: {...layerSpec, parentLayerRef: { id: parentLayerId, areaId: parentAreaId }}}));
  }
}

export default solidSlice.reducer;
