import { Typography } from '@mui/material';
import React from 'react';
import { ProfileSelectionDialog } from '../ProfileSelectionDialog';
import { CustomImageButton } from './CustomImageButton';
import { useGetImagesQuery } from 'luminopix-ui-common';

export function ImageSelectionDialog(props) {
  const apiQueryResults = useGetImagesQuery();

  const getMedia = profile => (
    {
      mediaType: "img",
      src: profile.thumbnailUrl,
      alt: `Preview of ${profile.name}`,
    }
  )

  return (
    <ProfileSelectionDialog
      dialogTitle="Select image"
      apiQueryResults={apiQueryResults}
      getProfileMedia={getMedia}
      getProfileName={profile => profile.name}
      onSelect={props.onSelect}
      dialogOpen={props.dialogOpen}
      setDialogOpen={props.setDialogOpen}
      customProfileOptionViewFactory={onSelectImage => (<CustomImageButton onSelect={onSelectImage} />)}
    />
  );
}
