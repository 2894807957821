export class SequentialWriteBuffer {
    constructor(byteLength) {
        this.buffer = new ArrayBuffer(byteLength);
        this.bufferView = new Uint8Array(this.buffer, 0, byteLength);
        this.offset = 0;
    }

    getBuffer() {
        if (this.offset !== this.buffer.byteLength) {
            throw new Error(`Buffer write is not complete yet. Current offset: ${this.offset}. Buffer length: ${this.buffer.byteLength}.`);
        }
        return this.buffer;
    }

    writeFloat32(value) {
        const byteCount = 4;

        const tempBuffer = new ArrayBuffer(byteCount);
        const tempBufferWriteView = new Float32Array(tempBuffer, 0, 1);
        tempBufferWriteView[0] = value;

        this.writeBufferContents(tempBuffer, byteCount);
    }

    writeUint32(value) {
        const byteCount = 4;

        const tempBuffer = new ArrayBuffer(byteCount);
        const tempBufferWriteView = new Uint32Array(tempBuffer, 0, 1);
        tempBufferWriteView[0] = value;

        this.writeBufferContents(tempBuffer, byteCount);
    }

    writeUint16(value) {
        const byteCount = 2;

        const tempBuffer = new ArrayBuffer(byteCount);
        const tempBufferWriteView = new Uint16Array(tempBuffer, 0, 1);
        tempBufferWriteView[0] = value;

        this.writeBufferContents(tempBuffer, byteCount);
    }

    writeUint8(value) {
        this.bufferView[this.offset] = value;
        this.offset++;
    }

    writeBufferContents(sourceBuffer, byteCount) {
        const tempBufferReadView = new Uint8Array(sourceBuffer, 0, byteCount);
        for (let i = 0; i < byteCount; i++) {
            this.writeUint8(tempBufferReadView[i]);
        }
    }
}
