import { Box } from "@mui/system";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { removeLayer } from "../../redux/solid";
import { BorderPreview } from "../Border/BorderPreview";
import { StandPreview } from "../Stand/StandPreview";
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { useState } from "react";
import { useLayerSelector } from "../../redux/layerSelection";
import { LayerPropertiesView } from "./LayerPropertiesView";
import { HierarchyActionView } from "./HierarchyActionView";


export const LayerDetailsView = () => {
  const dispatch = useDispatch();

  const { hydratedPart } = useHydratedPart();
  const layerSelector = useLayerSelector();

  const { layer: selectedLayer } = hydratedPart.getLayerAndAreaId(layerSelector.selectedLayerNumber);

  const [ removeAlertOpen, setRemoveAlertOpen ] = useState(false);

  let previewImage;
  switch (selectedLayer?.type) {
    case 'border':
      previewImage = (
        <BorderPreview profile={selectedLayer.border.profile} />
      )
      break;
    case 'stand':
      previewImage = (
        <StandPreview profile={selectedLayer.stand.profile} />
      )
      break;
    case undefined:
      previewImage = (
        <></>
      )
      break;
    default:
      previewImage = (
        <img src={selectedLayer.image?.resourceLoader.result?.value?.base64Image} alt="" style={{maxWidth: "100%", maxHeight: "100%"}} />
      )
      break;
  }

  const LayerThumbnailView = selectedLayer ? (
    <Box sx={{display: 'flex', flexDirection: 'row', padding: "8px", margin: "8px"}}>
      <Box sx={{width: 120, height: 100}}>
        { previewImage }
      </Box>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Box sx={{flex: 1}}>
          <Button>
            <DeleteIcon onClick={() => {
              if (hydratedPart.layers.find(layer => layer.parentLayerRef?.id === selectedLayer.id)) { // The layer to be removed has children. Display a warning before removal.
                setRemoveAlertOpen(true);
              } else {
                dispatch(removeLayer({ layerId: selectedLayer.id }));
              }
            }} />
          </Button>
          <Dialog open={removeAlertOpen} onClose={() => setRemoveAlertOpen(false)}>
            <DialogTitle>Contained elements will be removed!</DialogTitle>
            <DialogContent><DialogContentText>By removing this element, all elements contained within this element will also be removed.</DialogContentText></DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setRemoveAlertOpen(false)}>Cancel</Button>
              <Button onClick={() => { setRemoveAlertOpen(false); dispatch(removeLayer({ layerId: selectedLayer.id })); }}>Remove</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  ) : <></>

  const layerDetailsView = selectedLayer ? (
    <Box sx={{bgcolor: "rgba(0, 0, 0, 0.1)"}}>
      { LayerThumbnailView }
    </Box>
  ) : <></>

  return (
    <>
      <Box sx={{padding: "16px"}}>
        <HierarchyActionView />
        { layerDetailsView }
        <LayerPropertiesView />
      </Box>
    </>
  );
}
