import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from './settings'
import solidReducer from './solid'
import layerSelectionReducer from './layerSelection'
import { apiSlice } from "luminopix-ui-common";

export default configureStore({
    reducer: {
        settings: settingsReducer,
        solid: solidReducer,
        layerSelection: layerSelectionReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
});
