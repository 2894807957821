import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { useLayerSelector } from "../../redux/layerSelection";
import { BorderPropertiesView } from "./BorderPropertiesView";
import { ImagePropertiesView } from "./ImagePropertiesView";
import { StandPropertiesView } from "./StandPropertiesView";
import { SolidPropertiesView } from "./SolidPropertiesView";

export const LayerPropertiesView = () => {
  const { hydratedPart } = useHydratedPart();
  const layerSelector = useLayerSelector();

  const { layer: selectedLayer, areaId: selectedAreaId } = hydratedPart.getLayerAndAreaId(layerSelector.selectedLayerNumber);
  console.log("Selected layer: ", selectedLayer, selectedAreaId);

  let propertiesView;
  if (!selectedLayer) {
    propertiesView = <SolidPropertiesView hydratedPart={hydratedPart} />
  } else {
    switch (selectedLayer.type) {
      case undefined:
        propertiesView = <></>;
        break;
      case 'border':
        propertiesView = <BorderPropertiesView key={selectedLayer.id} layer={selectedLayer} border={selectedLayer.border} />
        break;
      case 'stand':
        propertiesView = <StandPropertiesView key={selectedLayer.id} layer={selectedLayer} stand={selectedLayer.stand} />
        break;
      case 'image':
      case 'mask':
        propertiesView = <ImagePropertiesView key={selectedLayer.id} image={selectedLayer.image} layer={selectedLayer} isMask={selectedLayer.type === 'mask'} />
        break;
      default:
        propertiesView = <Typography>No editable properties for selected area</Typography>
        break;
    }
  }

  return (
    <Box sx={{width: 400, padding: "50px"}}>
      {propertiesView}
    </Box>
  )
}
