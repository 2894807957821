import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form } from "formik";
import { useState } from "react";
import { StandSelectionDialog } from "../Stand/StandSelectionDialog";
import { FixedPrecisionField } from "./FixedPrecisionField";
import { validateNumber } from "./validation";
import EditIcon from "@mui/icons-material/Edit"
import { LayerPhysicalPropertiesView } from "./LayerPhysicalPropertiesView";

export const StandPropertiesView = (props) => {
  const stand = props.stand;
  const layer = props.layer;

  const [standsDialogOpen, setStandsDialogOpen] = useState(false);

  return (
    <>
      <Box>
        <Button onClick={() => setStandsDialogOpen(true)}>
          Change profile <EditIcon />
        </Button>
        <StandSelectionDialog
          dialogOpen={standsDialogOpen}
          setDialogOpen={setStandsDialogOpen}
          onSelect={selectedStand => stand.update({profile: selectedStand.standProfile})}/>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={{
          outerHeight: stand.outerHeight,
          innerRise: stand.innerRise,
          sideSpread: stand.sideSpread,
          profile: stand.profile,
        }}
        validate={ values => {
          const errors = {};
          let errorsFound = false;
          errorsFound |= !validateNumber(values.outerHeight, {min: 1, max: 100}, error => { errors.outerHeight = error })
          errorsFound |= !validateNumber(values.innerRise, {min: 1, max: 100}, error => { errors.innerRise = error })
          errorsFound |= !validateNumber(values.sideSpread, {min: 1, max: 100}, error => { errors.sideSpread = error })

          if (!errorsFound) {
            stand.update(values);
          }

          return errors;
        }}
      >
        {formik => (
          <Form>
            <Stack sx={{width: 300}}>
              <FixedPrecisionField
                id="outerHeight"
                name="outerHeight"
                label="Outer height"
                unit="mm"
                onChange={value => { formik.setFieldValue("outerHeight", value) } }
                value={formik.values.outerHeight}
                error={formik.errors.outerHeight}
              />
              <FixedPrecisionField
                id="innerRise"
                name="innerRise"
                label="Inner rise"
                unit="mm"
                onChange={value => { formik.setFieldValue("innerRise", value) } }
                value={formik.values.innerRise}
                error={formik.errors.innerRise}
              />
              <FixedPrecisionField
                id="sideSpread"
                name="sideSpread"
                label="Side spread"
                unit="mm"
                onChange={value => { formik.setFieldValue("sideSpread", value) } }
                value={formik.values.sideSpread}
                error={formik.errors.sideSpread}
              />
            </Stack>
          </Form>
        )}
      </Formik>
      <LayerPhysicalPropertiesView layer={layer} />
    </>
  )
}
