import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { useCompositeImage } from "../../LithophanePart/CompositeImageProvider";
import { useDispatch } from "react-redux";
import { LayerPhysicalPropertiesView } from "./LayerPhysicalPropertiesView";
import { ImageSelectorButton } from "../Image/ImageSelectorButton";
import { MaskSelectorButton } from "../Mask/MaskSelectorButton";

export const ImagePropertiesView = (props) => {
  const image = props.image;
  const layer = props.layer;
  const { hydratedPart } = useHydratedPart();
  const { compositeImage } = useCompositeImage();

  const dispatch = useDispatch();

  const jimpImage = image.resourceLoader.result.value?.jimpImage;
  const expandObjectButton = props.isMask || !jimpImage ? <></> : (
    <Button onClick={() => {
      const imageAspectRatio = jimpImage.bitmap.width/jimpImage.bitmap.height;
      const containingLayerNumber = layer.parentLayerRef ? hydratedPart.layerNumberMap.getLayerNumber(layer.parentLayerRef.id, layer.parentLayerRef.areaId) : -1;
      const imageAreaBounds = compositeImage.value.layerAreaBounds.getBounds(containingLayerNumber);
      const areaBoundsWidth = imageAreaBounds.maxCol-imageAreaBounds.minCol+1;
      const areaBoundsHeight = imageAreaBounds.maxRow-imageAreaBounds.minRow+1;
      const areaBoundsAspectRatio = areaBoundsWidth/areaBoundsHeight;
      const aspectRatioCorrectionFactor = areaBoundsAspectRatio/imageAspectRatio;
      console.log("Aspect ratios:", areaBoundsAspectRatio, imageAspectRatio, aspectRatioCorrectionFactor);

      if (Math.abs(aspectRatioCorrectionFactor-1) < Math.max(2/areaBoundsWidth, 2/areaBoundsHeight)) { // If the expected difference in image dimensions is less than 2 pixels, do not resize the object.
        return;
      }
      
      // Now we want to find how much to add to width or height of object so that the image is of correct aspect ratio. When object is scaled, any image or mask
      // parent will be scaled by the same factor as each other, while stand or border dimensions will remain constant.
      let unscalableWidth = 0;
      let unscalableHeight = 0;
      let currentLayerId = layer.parentLayerRef.id;
      while (currentLayerId && currentLayerId !== -1) {
        const currentLayer = hydratedPart.getLayerById(currentLayerId);
        if (currentLayer.type === 'border') {
          unscalableWidth += currentLayer.border.width * 2;
          unscalableHeight += currentLayer.border.width * 2;
        } else if (currentLayer.type === 'stand') {
          unscalableWidth += currentLayer.stand.sideSpread * 2;
          unscalableHeight += currentLayer.stand.outerHeight + currentLayer.stand.innerRise;
        }

        currentLayerId = currentLayer.parentLayerRef?.id;
      }

      if (aspectRatioCorrectionFactor < 1) {
        const widthDeficiency = (hydratedPart.dimensions.width-unscalableWidth)*(1/aspectRatioCorrectionFactor-1);
        hydratedPart.update({width: hydratedPart.dimensions.width + widthDeficiency, height:hydratedPart.dimensions.height}, dispatch);
      } else {
        const heightDeficiency = (hydratedPart.dimensions.height-unscalableHeight)*(aspectRatioCorrectionFactor-1);
        hydratedPart.update({width: hydratedPart.dimensions.width, height:hydratedPart.dimensions.height + heightDeficiency}, dispatch);
      }
    }}>
      Expand object to fit image
    </Button>
  );

  // In the lines below, use of null rather than undefined forces updating the fields.
  const changeButton = props.isMask ? (
    <MaskSelectorButton onSelect={selectedImage => image.update({downloadUrl: selectedImage.downloadUrl ?? null, locatingUrl: selectedImage.locatingUrl ?? null})}>
      Change mask <EditIcon />
    </MaskSelectorButton>
  ) : (
    <ImageSelectorButton onSelect={selectedImage => image.update({downloadUrl: selectedImage.downloadUrl ?? null, locatingUrl: selectedImage.locatingUrl ?? null})}>
      Change image <EditIcon />
    </ImageSelectorButton>
  )

  return (
    <>
      <Box>
        { changeButton }
        {expandObjectButton}
      </Box>
      { props.isMask ? null : <LayerPhysicalPropertiesView layer={layer} /> }
    </>
  )
}
