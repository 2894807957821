export const StopWatch = () => {
  let startTime = new Date().getTime();

  const reset = () => {
    startTime = new Date().getTime();
  }

  const elapsed = () => new Date().getTime() - startTime;

  return Object.freeze({
    reset,
    elapsed,
  });
}
