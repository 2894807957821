export const validateNumber = (number, {min, max}, setError) => {
  if (!number && number !== 0) {
    setError("Cannot be empty");
    return false;
  }

  const numericalValue = Number(number);
  if (Number.isNaN(numericalValue)) {
    setError(`Must be a number`);
    return false;
  }

  if ((min && numericalValue < min) || (max && numericalValue > max)) {
    setError(`Must be between ${min} and ${max}`);
    return false;
  }

  return true;
}
