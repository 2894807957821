import { interpolate } from "../../math/interpolate";

export const applyStand = (layerMap, depthMap, layerAreaBounds, hydratedStand, buildSettings, standLayerNumber, leftSurroundingLayerNumber, rightSurroundingLayerNumber, containedLayerNumber) => {
  const standProfile = hydratedStand.profile;
  const outerHeightRows = hydratedStand.outerHeight / buildSettings.pitch.verticalPitch;
  const innerRiseRows = hydratedStand.innerRise / buildSettings.pitch.verticalPitch;
  const sideSpreadCols = hydratedStand.sideSpread / buildSettings.pitch.horizontalPitch;
  const maxRow = layerMap.length-1; // inclusive limit
  const maxCol = layerMap[0].length-1; // inclusive limit
  for (let row = 0; row <= maxRow; row++) {
    for (let col = 0; col <= maxCol; col++) {
      if (row > maxRow-outerHeightRows) { // Lower (vertical) portion of stand
        layerMap[row][col] = standLayerNumber;
        depthMap[row][col] = 1-interpolate(standProfile, 0, maxCol, col);
      } else if (row > maxRow-outerHeightRows-innerRiseRows) { // Upper (sloped) portion of stand
        const sideGapRatio = (maxRow-outerHeightRows-row)/innerRiseRows;
        const scaledSideGapCols = Math.round(sideGapRatio*sideSpreadCols);
        if (col < scaledSideGapCols) { // Within left gap
          layerMap[row][col] = leftSurroundingLayerNumber;
        } else if (col > maxCol - scaledSideGapCols) { // Within right gap
          layerMap[row][col] = rightSurroundingLayerNumber;
        } else {
          layerMap[row][col] = standLayerNumber;
          depthMap[row][col] = 1-(1-sideGapRatio)*interpolate(standProfile, scaledSideGapCols, maxCol - scaledSideGapCols, col);
        }
      } else { // Above stand
        if (col < sideSpreadCols) { // Within left gap
          layerMap[row][col] = leftSurroundingLayerNumber;
        } else if (col > maxCol - sideSpreadCols) { // Within right gap
          layerMap[row][col] = rightSurroundingLayerNumber;
        } else {
          layerMap[row][col] = containedLayerNumber;
        }
      }
    }
  }

  layerAreaBounds.setBounds(standLayerNumber, {minRow: maxRow-outerHeightRows-innerRiseRows+1, minCol: 0, maxRow: maxRow, maxCol: maxCol});
  layerAreaBounds.setBounds(containedLayerNumber, {minRow: 0, minCol: sideSpreadCols, maxRow: maxRow-outerHeightRows-innerRiseRows, maxCol: maxCol-sideSpreadCols});
  // Will not set these because they are currently not actual areas that are kept track of in the map.
  // layerAreaBounds.setBounds(leftSurroundingLayerNumber, {minRow: 0, minCol: 0, maxRow: maxRow-outerHeightRows, maxCol: sideSpreadCols});
  // layerAreaBounds.setBounds(rightSurroundingLayerNumber, {minRow: 0, minCol: maxCol-sideSpreadCols+1, maxRow: maxRow-outerHeightRows, maxCol: maxCol});
}
