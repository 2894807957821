import { Button, Card, CardActionArea, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';

export function ProfileSelectionDialog(props) {
  let [dialogOpen, setDialogOpen] = [props.dialogOpen, props.setDialogOpen];
  const closeDialog = () => {
    dialogOpen = false; // So that clicking a selection multiple times before the dialog closes does not result in calling props.onSelect.
    setDialogOpen(false);
  }

  const {
    data: profiles,
    isLoading,
    isSuccess,
    isError,
    error
  } = props.apiQueryResults; // e.g. result of useGetBordersQuery()

  const selectProfile = (profile) => {
    if (dialogOpen) {
      closeDialog();
      props.onSelect(profile);
    }
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="xl" PaperProps={{sx: {height: "100%"}}}>
      <DialogTitle>
        {props.dialogTitle}
        {
          props.customProfileOptionViewFactory ?
            <>
              <Typography sx={{margin: "8px", padding: "8px"}} variant="button">or</Typography>
              {
                props.customProfileOptionViewFactory(selectedProfile => {
                  if (selectedProfile) { // If no profile was selected, do nothing and keep the dialog open.
                    selectProfile(selectedProfile);
                  }
                })
              }
            </>
          : null
        }
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {
            isSuccess && profiles.map((profile, index) => (
              <Grid item key={index}>
                <Card sx={{width:300, height:300}}>
                  <CardActionArea onClick={() => selectProfile(profile)}>
                    <CardContent>
                      <Tooltip title={props.getProfileName(profile)}>
                        <Typography noWrap>{props.getProfileName(profile)}</Typography>
                      </Tooltip>
                      {
                        // TODO: Remove when border and stand thumbnails are displayed as precalculated thumbnail images
                        props.getProfileMedia(profile).mediaType !== "img" ? props.getProfileMedia(profile).element : null
                      }
                    </CardContent>
                    {
                      // TODO: No need for conditional expression when border and stand thumbnails are displayed as precalculated thumbnail images
                      props.getProfileMedia(profile).mediaType === "img" ?
                        <CardMedia
                          height="240"
                          component="img"
                          sx={{
                            objectFit: "contain"
                          }}
                          {
                            ...props.getProfileMedia(profile)
                          }
                        />
                      : null
                    }
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button autoFocus onClick={closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}