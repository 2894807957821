import { StopWatch } from "./StopWatch";

export const PriorityYielder = (minimumTimeBeforeYieldMilliseconds = 0) => {
  const stopWatch = StopWatch();
  const tryYield = async () => {
    if (stopWatch.elapsed() >= minimumTimeBeforeYieldMilliseconds) {
      await new Promise(resolve => setTimeout(resolve)); // Based on https://stackoverflow.com/questions/61338780/is-there-a-faster-way-to-yield-to-javascript-event-loop-than-settimeout0
      console.log("yielded priority after ", stopWatch.elapsed());
      stopWatch.reset();
    }
  }

  return Object.freeze({
    tryYield,
  });
}
