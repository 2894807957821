export const CancellationToken = () => {
  let isCancelled = false;

  const cancel = () => {
    isCancelled = true;
  }

  const cancelled = () => {
    return isCancelled;
  }

  const throwIfCancelled = () => {
    if (isCancelled) {
      throw new Error("Task was cancelled");
    }
  }

  return {
    cancel,
    cancelled,
    throwIfCancelled,
  };
}
