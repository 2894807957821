import { labelAndMapConnectedComponents } from "./ImageSegmentation";

export const applyImage = (layerMap, depthMap, layerAreaBounds, hydratedImage, targetLayerNumber, layerNumbersByAreaId) => {
  const bounds = layerAreaBounds.getBounds(targetLayerNumber);
  if (!bounds) { // This can happen if an image in a parent layer has not been loaded yet.
    return;
  }
  const {minRow, minCol, maxRow, maxCol } = bounds; // Inclusive limits
  const resizedImage = hydratedImage.resourceLoader.result.value?.jimpImage.clone().resize(maxCol - minCol + 1, maxRow - minRow + 1).grayscale();
  if (!resizedImage) {
    return;
  }
  const maxMarginPoints = hydratedImage.resourceLoader.result.value?.maxMarginPoints;
  if (!maxMarginPoints) {
    return;
  }
  const labeledConnectedComponents = labelAndMapConnectedComponents(resizedImage, maxMarginPoints);

  const updateBoundingRectangle = (layerNumber, row, col) => {
    const boundingRectangle = layerAreaBounds.getBounds(layerNumber);
    let boundingMinRow = row, boundingMinCol = col, boundingMaxRow = row, boundingMaxCol = col;
    if (boundingRectangle) {
      ({ minRow: boundingMinRow, minCol: boundingMinCol, maxRow: boundingMaxRow, maxCol: boundingMaxCol } = boundingRectangle);
    }
    layerAreaBounds.setBounds(layerNumber, { minRow: Math.min(row, boundingMinRow), minCol: Math.min(col, boundingMinCol), maxRow: Math.max(row, boundingMaxRow), maxCol: Math.max(col, boundingMaxCol) });
  }

  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      const imageRow = row-minRow;
      const imageCol = col-minCol;
      if (layerMap[row][col] === targetLayerNumber) {
        const areaId = labeledConnectedComponents[imageRow][imageCol];
        layerMap[row][col] = layerNumbersByAreaId.get(areaId);
        if (layerMap[row][col] === hydratedImage.opaqueLayerNumber) {
          depthMap[row][col] = resizedImage.getPixelColor(imageCol, imageRow)/0xFFFFFFFF;
        }
        updateBoundingRectangle(layerMap[row][col], row, col);
      }
    }
  }
}
