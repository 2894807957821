import { Box, Button, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { addSurroundingLayer } from "../../redux/solid";
import { StandSelectionDialog } from "../Stand/StandSelectionDialog";

export const GlobalActionsView = () => {
  const dispatch = useDispatch();

  const { hydratedPart } = useHydratedPart();

  const [ standsDialogOpen, setStandsDialogOpen ] = useState(false);

  const addStand = selectedStand => {
    if (selectedStand) {
      addSurroundingLayer({
        layer: {
          id: nanoid(),
          type: "stand",
          reverseSide: "mirrored",
          minThickness: 1.6,
          maxThickness: 20,
          standSpec: {
            outerHeight: 5,
            innerRise: 5,
            sideSpread: 5,
            profile: selectedStand.standProfile,
          },
        }
      }, 1, hydratedPart.getTopLayer(), dispatch);
    }
  }

  const addStandButton = (
    <Box>
      <Button onClick={ () => setStandsDialogOpen(true) } >
        Add a stand
      </Button>
      <StandSelectionDialog dialogOpen={standsDialogOpen} setDialogOpen={setStandsDialogOpen} onSelect={selectedStand => addStand(selectedStand)}/>
    </Box>
  )

  return (
    <>
      { hydratedPart.layers.length ? <Typography>Click a region in the preview above to view available actions.</Typography> : null }
      { hydratedPart.stand ? null : addStandButton }
    </>
  )
}
