import './EditorAppView.css';
import { Box } from '@mui/material';
import SolidView from './view/SolidView'
import React from 'react';
import { ButtonDrawerView } from './view/ButtonDrawerView';
import { ImageDrawerView } from './view/ImageDrawerView';

export default function EditorAppView() {
  return (
    <>
      <Box sx={{ display:"flex", textAlign: "center", width: "100%", height: "100%" }}>
        <ButtonDrawerView />
        <SolidView />
        <ImageDrawerView />
      </Box>
    </>
  );
}
