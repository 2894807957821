import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from "react";
import { Recomputable } from '../async/Recomputable';
import { computeFacesForDisplay } from '../SolidModel/LithophanePart';
import { useCompositeImage } from './CompositeImageProvider';


const solidFacesContext = React.createContext();

export const SolidFacesProvider = (props) => {
  const [changeId, setChangeId] = useState(0);

  const { compositeImage, changeId: compositeImageChangeId } = useCompositeImage();

  const [coordinateCollections] = useState(Recomputable(null));

  useEffect(() => {
    // Always recompute faces if composite image is updated
    coordinateCollections.recompute(async (cancellationToken) => computeFacesForDisplay(compositeImage.value, cancellationToken), () => setChangeId(nanoid()));
  }, [compositeImageChangeId]);


  return <solidFacesContext.Provider value={{coordinateCollections: coordinateCollections.result, changeId}}>{props.children}</solidFacesContext.Provider>
}

export const useSolidFaces = () => {
  return React.useContext(solidFacesContext);
}
