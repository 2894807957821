export const interpolate = (valueArray, rangeStart, rangeEnd, rangeValue) => {
  if (rangeEnd === rangeStart) {
    return (valueArray[0]+valueArray[valueArray.length-1]) / 2
  }
  const rangeRatioFromStart = (rangeValue-rangeStart)/(rangeEnd-rangeStart);
  const floatIndex = rangeRatioFromStart*(valueArray.length-1);
  const floor = Math.floor(floatIndex);
  let floorDistance = floatIndex-floor;
  const ceil = Math.ceil(floatIndex);
  let ceilDistance = ceil-floatIndex;
  if (floorDistance === ceilDistance) { // Can be because floor === ceil or because fractional part of floatIndex is exactly 0.5. It both cases, use equal weights when averaging.
    floorDistance = 0.5;
    ceilDistance = 0.5;
  }
  return valueArray[floor]*ceilDistance + valueArray[ceil]*floorDistance;
}
