import Vertex from "../SolidGeometry/Vertex";
import Face from "../SolidGeometry/Face";
import { PriorityYielder } from "../async/PriorityYielder";
import { FaceCoordinatesCollection } from "../SolidGeometry/FaceCoordinatesCollection";

export async function createCoordinatesBuffer(compositeImage, cancellationToken) {
  let floatValues = await computeFacesForDisplay(compositeImage, cancellationToken);
  return floatValues ? Float32Array.from(floatValues.coordinates) : Float32Array.from([]);
}

export async function computeFacesForDisplay(compositeImage, cancellationToken) {
  // As a first approximation attempt, each pixel will correspond to only one point. It will be connected to six
  // of its neighbors (two horizontal, two vertical, and two on one diagonal direction). This means it will be
  // part of the vertices of six triangles.
  //
  // To produce the triangles, we can loop over each pixel (except ones in the last row or last column) and
  // generate the two triangles of which it corresponds to the top-left vertex.

  if (!compositeImage || !compositeImage.layerMap || !compositeImage.depthMap) {
    return null;
  }
  const buildSettings = compositeImage.buildSettings;
  const isMirrored = compositeImage.hydratedPart.reverseSide === "mirrored";

  const yielder = PriorityYielder(20);

  const { layerMap, depthMap } = compositeImage;

  const imageRows = depthMap.length;
  const imageCols = depthMap[0].length;
  const vertexHeight = buildSettings.pitch.horizontalPitch;
  const vertexWidth = buildSettings.pitch.verticalPitch;
  
  const isTransparentLayerNumber = (layerNumberInMap) => {
    if (layerNumberInMap === -1) {
      return true;
    }
    const layerAndAreaId = compositeImage.hydratedPart.getLayerAndAreaId(layerNumberInMap);
    if (!layerAndAreaId) { // This may happen if an image is still being loaded.
      return true;
    }
    const { layer, areaId } = layerAndAreaId;
    return areaId > 0 || layer.type === "mask"; // If the layer type is "mask" in an otherwise non-transparent pixel in the final layer map, this means it is part of the mask-covered areas, so it is meant to be transparent.
  }

  const isOutOfRange = (row, col) => {
    return (row < 0 || row >= imageRows || col < 0 || col >= imageCols);
  }

  const isTransparent = (row, col) => {
    if (isOutOfRange(row, col)) {
      return true;
    }
    return isTransparentLayerNumber(layerMap[row][col]);
  }

  const getVertex = (row, col) => {
    const { layer, areaId } = compositeImage.hydratedPart.getLayerAndAreaId(layerMap[row][col]);
    if (!layer) {
      console.log(row, col, layerMap[row][col])
    }
    const minThickness = layer.minThickness;
    const maxThickness = layer.maxThickness;
    let thickness = (1-depthMap[row][col])*(maxThickness-minThickness) + minThickness;
    if (isMirrored) {
      thickness /= 2;
    }
    return new Vertex(thickness, vertexWidth*col, vertexHeight*(imageRows-row-1));
  }
  
  
  // The generated panel is aligned with the y-z plane, in the positive quarter of the plane. The back of the panel is
  // aligned with the yz-plane, and the thickness of the panel portrudes in the positive x direction.
  

  const getSurfaceFaces = (v1, v2, v3) => {
    let face = new Face(getVertex(...v1), getVertex(...v2), getVertex(...v3));
    // Add the back as individual triangles for each vertex even though the whole back is only one large triangle,
    // so that the code can be easily adapted for the case where the back is curved
    if (isMirrored) {
      return [face, face.get_mirror_on_yz_plane().reverse()];
    } else {
      return [face, face.get_projection_on_yz_plane().reverse()];
    }
  }

  const getSideFaces = (v1, v2) => {
    // Assumption: v1 to v2 goes in the positive rotation direction.
    if (isMirrored) {
      return [new Face(getVertex(...v1), getVertex(...v2), getVertex(...v2).get_mirror_on_yz_plane()),
              new Face(getVertex(...v1), getVertex(...v2).get_mirror_on_yz_plane(), getVertex(...v1).get_mirror_on_yz_plane())];
    } else {
      return [new Face(getVertex(...v1), getVertex(...v2), getVertex(...v2).get_projection_on_yz_plane()),
              new Face(getVertex(...v1), getVertex(...v2).get_projection_on_yz_plane(), getVertex(...v1).get_projection_on_yz_plane())];
    }
  }

  const createFaceCoordinateCollection = async (layerNumberInMap) => {
    const coordinatesCollection = new FaceCoordinatesCollection(layerNumberInMap);
    for (let row = 0; row < imageRows; row++) {
      cancellationToken.throwIfCancelled();
      await yielder.tryYield();
      cancellationToken.throwIfCancelled();
      for (let col = 0; col < imageCols; col++) {
        if (layerMap[row][col] !== layerNumberInMap) {
          continue;
        }

        if (!isTransparent(row, col)) {
          if (!isTransparent(row+1, col+1)) {
            if (!isTransparent(row+1, col)) {
                coordinatesCollection.addFaces(...getSurfaceFaces([row, col], [row+1, col], [row+1, col+1]));
            } else if (!isTransparent(row, col+1)) {
                coordinatesCollection.addFaces(...getSideFaces([row+1, col+1], [row, col]));
            }
      
            if (!isTransparent(row, col+1)) {
                coordinatesCollection.addFaces(...getSurfaceFaces([row, col], [row+1, col+1], [row, col+1]));
            } else if (!isTransparent(row+1, col)) {
                coordinatesCollection.addFaces(...getSideFaces([row, col], [row+1, col+1]));
            }
          }
          else {
            if (!isTransparent(row+1, col) && !isTransparent(row, col+1)) {
                coordinatesCollection.addFaces(...getSurfaceFaces([row, col], [row+1, col], [row, col+1]));
                coordinatesCollection.addFaces(...getSideFaces([row, col+1], [row+1, col]));
            } else {
              if (!isTransparent(row+1, col)) {
                  coordinatesCollection.addFaces(...getSideFaces([row, col], [row+1, col]));
              }
              if (!isTransparent(row, col+1)) {
                  coordinatesCollection.addFaces(...getSideFaces([row, col+1], [row, col]));
              }
            }
          }

          // A vertex is "responsible" for creating faces for the cell to its bottom right. However,
          // if the vertex to its top left is transparent, the vertex becomes responsible also for
          // the faces to its top left.
          if (isTransparent(row-1, col-1)) {
            if (!isTransparent(row-1, col) && !isTransparent(row, col-1)) {
                coordinatesCollection.addFaces(...getSurfaceFaces([row, col], [row-1, col], [row, col-1]));
                coordinatesCollection.addFaces(...getSideFaces([row, col-1], [row-1, col]));
            } else {
              if (!isTransparent(row-1, col)) {
                  coordinatesCollection.addFaces(...getSideFaces([row, col], [row-1, col]));
              }
              if (!isTransparent(row, col-1)) {
                  coordinatesCollection.addFaces(...getSideFaces([row, col-1], [row, col]));
              }
            }
          }
        }
      }
    }
    
    return coordinatesCollection;
  }
  
  const coordinateCollections = [];
  const uniqueLayersInMap = [...new Set(layerMap.flat())].filter(layerNumber => !isTransparentLayerNumber(layerNumber));
  for (const layerNumberInMap of uniqueLayersInMap) {
    coordinateCollections.push(await createFaceCoordinateCollection(layerNumberInMap));
  }

  return coordinateCollections;
}
