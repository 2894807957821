import { applyBorder } from "./Border";
import { applyImage } from "./Image";
import { applyStand } from "./Stand";

export const getLayerAndRelativeDepthMaps = (buildSettings, width, height, hydratedPart) => {
  const rowHeight = buildSettings.pitch.verticalPitch;
  const colWidth = buildSettings.pitch.horizontalPitch;
  const rowCount = Math.ceil(height/rowHeight);
  const colCount = Math.ceil(width/colWidth);
  const layerMap = Array.from({length: rowCount}, () => new Array(colCount).fill(-1));
  const depthMap = Array.from({length: rowCount}, () => new Array(colCount).fill(0));

  const layerAreaBounds = (() => {
    const bounds = new Map();
    const setBounds = (layerNumber, boundsToSet) => {
      bounds.set(layerNumber, boundsToSet);
    }
    const getBounds = (layerNumber) => {
      if (layerNumber === -1) {
        return { minRow: 0, minCol: 0, maxRow: layerMap.length-1, maxCol: layerMap[0].length-1 };
      } else {
        return bounds.get(layerNumber);
      }
    }

    return Object.freeze({
      setBounds,
      getBounds,
    })
  })();

  const applyLayer = (layer) => {
    const targetLayerNumber = layer.parentLayerRef ? hydratedPart.layerNumberMap.getLayerNumber(layer.parentLayerRef.id, layer.parentLayerRef.areaId) : -1;
    if (layer.type === "border") {
      applyBorder(layerMap, depthMap, layerAreaBounds, layer.border, buildSettings, targetLayerNumber, layer.border.borderLayerNumber, layer.border.containedLayerNumber);
    } else if (layer.type === "image" || layer.type === "mask") {
      applyImage(layerMap, depthMap, layerAreaBounds, layer.image, targetLayerNumber, hydratedPart.layerNumberMap.getLayerNumbersByAreaId(layer.id));
    }
  } 

  const stand = hydratedPart.stand;
  if (stand) {
    applyStand(layerMap, depthMap, layerAreaBounds, stand.stand, buildSettings, stand.stand.standLayerNumber, -1, -1, stand.stand.containedLayerNumber);
  }

  for (let layer of hydratedPart.getLayersTopToBottom()) {
    applyLayer(layer.layer);
  }

  return {layerMap, depthMap, layerAreaBounds};
}
