import { Button } from "@mui/material";
import { useState } from "react";
import { MaskSelectionDialog } from "./MaskSelectionDialog";

export const MaskSelectorButton = (props) => {
  const [ dialogOpen, setDialogOpen ] = useState(false);
  return (
    <>
      <Button onClick={() => { setDialogOpen(true); }}>
        {props.children}
      </Button>
      <MaskSelectionDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} onSelect={props.onSelect}/>
    </>
  )
}
