import FileSaver from 'file-saver'
import { SequentialWriteBuffer } from './SequentialWriteBuffer';

export class StlWriter {
  async writeFile(fileName, faceCollections) {
  if (!faceCollections) {
    console.log("No triangles provided. Cannot create STL...");
    return;
  }

  const totalFaceCount = faceCollections.reduce((sum, faceCollection) => sum + faceCollection.getFaceCount(), 0);
  
  const fileSize = totalFaceCount * 50 + 84;
  const buffer = new SequentialWriteBuffer(fileSize);
  
  const headerLength = 80;
  for (let headerByteIndex = 0; headerByteIndex < headerLength; headerByteIndex++) {
    buffer.writeUint8(0);
  }
  buffer.writeUint32(totalFaceCount);

  for (const faceCollection of faceCollections) {
    for (let i = 0; i < faceCollection.getFaceCount(); i++) {
      const face = faceCollection.getFace(i);
      const triangleNormal = face.getNormal();
    
      triangleNormal.toFloat32Array().concat(face.toFloat32Array()).forEach(coordinate => {
        buffer.writeFloat32(coordinate);
      });

      buffer.writeUint16(0);
    };
  }

  const blob = new Blob([buffer.getBuffer()]);
  FileSaver.saveAs(blob, fileName);
  }
}
