import { Button, Card, CardActionArea, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetTemplatesQuery } from 'luminopix-ui-common';
import { resetSolid } from '../redux/solid';

export function TemplateSelectionDialog(props) {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = [props.dialogOpen, props.setDialogOpen];
  const closeDialog = () => setDialogOpen(false);

  const {
    data: templates,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetTemplatesQuery();

  function selectTemplate(template) {
    dispatch(resetSolid({
      solidSpec: JSON.parse(template.solidSpec)
    }));
    closeDialog();
  }

  useEffect(() => { // The code below is wrapped in useEffect to avoid the warning about this component updating the state of another component during render (the state is updated through props.setDialogOpen)
    if (window.template) {
      selectTemplate(window.template);
      delete window.template;
    }
  });

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="xl" PaperProps={{sx: {height: "100%"}}}>
      <DialogTitle>Select a template</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} >
          {
            isSuccess && templates.map((template, index) => (
              <Grid item key={index}>
                <Card sx={{width:300, height:300}}>
                  <CardActionArea onClick={() => selectTemplate(template)}>
                    <CardContent>
                      <Typography>{template.name}</Typography>
                      <Typography>{template.description}</Typography>
                      {template.thumbnailUrl ? <CardMedia component='img' src={template.thumbnailUrl} /> : ''}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}