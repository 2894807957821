import { Box } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';
import Jimp from 'jimp';
import { useEffect, useRef, useState } from 'react';
import { Recomputable } from '../async/Recomputable';
import { useCompositeImage } from '../LithophanePart/CompositeImageProvider';
import { useLayerSelector } from '../redux/layerSelection';
import { useSolid } from '../redux/solid';

export const ImagePreviewView = () => {
  const {compositeImage} = useCompositeImage();

  const solid = useSolid();
  console.log("Solid spec:", JSON.stringify(solid));

  const layerSelector = useLayerSelector();

  const [previewImage] = useState(Recomputable(null));
  const [changeId, setChangeId] = useState(0); // Used only to make sure the component rerenders when computation is done.
  useEffect(() => {
    previewImage.recompute(() => compositeImage.value?.getColorLabeledImage(layerSelector.selectedLayerNumber).getBase64Async(Jimp.MIME_PNG), () => setChangeId(nanoid()));
  }, [compositeImage.value, layerSelector.selectedLayerNumber]);

  const imageElement = useRef();

  const handleImageClick = (event) => {
    if (compositeImage.value && previewImage.result?.value && imageElement) {
      const boundingRectangle = imageElement.current.getBoundingClientRect();
      const xPx = event.pageX - boundingRectangle.left;
      const yPx = event.pageY - boundingRectangle.top;
      const xOffsetRatio = xPx / (boundingRectangle.right - boundingRectangle.left);
      const yOffsetRatio = yPx / (boundingRectangle.bottom - boundingRectangle.top);
      const clickedLayerIndex = compositeImage.value.getLayerIndexForOffsetRatio(xOffsetRatio, yOffsetRatio);
      layerSelector.toggleSelectedLayerNumber(clickedLayerIndex);
    }
  }

  return previewImage.result?.value ? (
    <Box sx={{padding: "16px", width: "100%", height: "100%", boxSizing:"border-box", justifyContent: "center"}}>
      <img alt="Combined layers" src={previewImage.result.value} style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain", flexShrink: 0, imageRendering: "smooth"}} onClick={event => handleImageClick(event) } ref={imageElement} />
    </Box>
  ) : null;
}
