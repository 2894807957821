import { Box } from '@mui/material';
import { ImagePreviewView } from './ImagePreviewView';
import { LayerDetailsView } from './input/LayerDetailsView';


export function ImageDrawerView() {
  return (
    <Box sx={{ flex:'1 0', backgroundColor: "lightblue" }}>
      <Box sx={{ width: "100%", height: "30%", boxSizing:"border-box", justifyContent:"center" }}>
        <ImagePreviewView />
      </Box>
      <Box sx={{ justifyContent:"center" }}>
        <LayerDetailsView />
      </Box>
    </Box>
  );
}
