import { InputAdornment, TextField } from "@mui/material"
import { useState } from "react";

export const FixedPrecisionField = (props) => {
  const step = props.step ?? 0.01;

  const reformat = value => {
    const numericValue = Number(value);
    return Number.isNaN(numericValue) ? value : (Math.round(numericValue/step)*step).toFixed(Math.ceil(-Math.log10(step)-1e-5));
  }

  const [value, setValue] = useState(reformat(props.value));

  const reformattedValue = reformat(value);

  const reformatIfNeeded = () => {
    if (value !== reformattedValue) {
      setValue(reformattedValue);
    }
  }

  const finishChange = () => {
    reformatIfNeeded();
    props.onChange(reformattedValue);
  }

  return (
    <TextField
      variant="outlined"
      label={props.label}
      margin="dense"
      InputProps={{ endAdornment: <InputAdornment position="end">{props.unit}</InputAdornment> }}
      value={value}
      error={props.error !== null && props.error !== undefined}
      helperText={props.error ?? " "} // Space character is to reserve height on screen when there is no error
      onChange={event => {
        setValue(event.target.value);
      }}
      onBlur={finishChange}
    />
  )
}