import { Button, Typography } from "@mui/material";
import { LIGHT_COLOR } from "luminopix-ui-common";
import { useState } from "react";
import { CustomMaskDialog } from "./CustomMaskDialog";

export const CustomMaskButton = (props) => {
  const [ customMaskDialogOpen, setCustomMaskDialogOpen ] = useState(false);

  return (
    <>
      <Button onClick={() => setCustomMaskDialogOpen(true)} sx={{backgroundColor: "darkgreen", color: LIGHT_COLOR }}>
        Use a custom mask
      </Button>
      <CustomMaskDialog dialogOpen={customMaskDialogOpen} setDialogOpen={setCustomMaskDialogOpen} onSelect={props.onSelect}/>
    </>
  )
}