import { Stack } from "@mui/material";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { FixedPrecisionField } from "./FixedPrecisionField";
import { MultipleChoiceField } from "./MultipleChoiceField";
import { validateNumber } from "./validation";

export const SolidPropertiesView = (props) => {
  const hydratedPart = props.hydratedPart;
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          width: hydratedPart.dimensions?.width,
          height: hydratedPart.dimensions?.height,
          reverseSide: hydratedPart.reverseSide,
        }}
        validate={ values => {
          const errors = {};
          let errorsFound = false;
          errorsFound |= !validateNumber(values.width, {min: 1, max: 1000}, error => { errors.width = error })
          errorsFound |= !validateNumber(values.height, {min: 1, max: 1000}, error => { errors.height = error })
          
          if (!errorsFound) {
            hydratedPart.update(values, dispatch);
          }

          return errors;
        }}
      >
        {formik => (
          <Form>
            <Stack sx={{width: 300}}>
              <FixedPrecisionField
                id="width"
                name="width"
                label="Width"
                unit="mm"
                onChange={value => { formik.setFieldValue("width", value) } }
                value={formik.values.width}
                error={formik.errors.width}
              />
              <FixedPrecisionField
                id="height"
                name="height"
                label="Height"
                unit="mm"
                onChange={value => { formik.setFieldValue("height", value) } }
                value={formik.values.height}
                error={formik.errors.height}
              />
              <MultipleChoiceField
                id="reverseSide"
                name="reverseSide"
                selectedKey={hydratedPart.reverseSide}
                choices={[{key: "flat", label: "Flat back"}, {key: "mirrored", label: "Mirrored"}]}
                onChange={value => { formik.setFieldValue("reverseSide", value) } }
                value={formik.values.reverseSide}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  )
}
