import { PriorityQueue, Queue } from "buckets-js"
import { interpolate } from "../../math/interpolate";


const EXTERIOR_SPACE = 0;
const INTREIOR_SPACE = 1;
const EXTERIOR_BOUNDARY = 2;
const INTERIOR_BOUNDARY = 3;
const SOLID_NON_BOUNDARY = 4;


export const labelBorders = (layerMap) => {
  // To get the boundaries, regardless of whether the object is one connected component, start from the outside edges
  // of the array and perform a flood fill of transparent pixels. The non-transparent pixels encountered are the
  // boundaries of the object.
  const minrow = 0;
  const mincol = 0;
  const maxrow = layerMap.length; // non-inclusive limit
  const maxcol = layerMap[0].length; // non-inclusive limit
  const labeledImageArray = layerMap.map(line => line.map(_ => -1)); // 0 means exterior space, 1 means interior space. 2 means exterior boundary. 3 means interior boundary. 4 means nonboundary. Initialize to all -1.

  const floodFillQueue = new Queue();

  function AddPixelNeighborsToQueue(row, col) {
    floodFillQueue.enqueue({row: row-1, col});
    floodFillQueue.enqueue({row: row+1, col});
    floodFillQueue.enqueue({row, col: col-1});
    floodFillQueue.enqueue({row, col: col+1});
  }

  function LabelSpaceOrBoundary(row, col, isExterior, pushToQueue = false) {
    if (layerMap[row][col] !== -1) {
      labeledImageArray[row][col] = isExterior ? EXTERIOR_BOUNDARY : INTERIOR_BOUNDARY;
    } else {
      if (pushToQueue) {
        AddPixelNeighborsToQueue(row, col);
      }
      labeledImageArray[row][col] = isExterior ? EXTERIOR_SPACE : INTREIOR_SPACE;
    }
  }

  for (let row = minrow; row < maxrow; row++) {
    LabelSpaceOrBoundary(row, 0, true, true); // If a node is untransparent and on the edges of the image, it is also on the outer boundary of the object
    LabelSpaceOrBoundary(row, maxcol-1, true, true);
  }
  for (let col = mincol; col < maxcol; col++) {
    LabelSpaceOrBoundary(0, col, true, true);
    LabelSpaceOrBoundary(maxrow-1, col, true, true);
  }

  function floodFill(isExterior) {
    while (!floodFillQueue.isEmpty()) {
      const {row, col} = floodFillQueue.dequeue();
      if (row < 0 || row >= maxrow || col < 0 || col >= maxcol) {
        continue;
      }
      if (labeledImageArray[row][col] !== -1) {
        // Cell has already been processed
        continue;
      }
      LabelSpaceOrBoundary(row, col, isExterior);
      if (layerMap[row][col] === -1) {
        AddPixelNeighborsToQueue(row, col);
      }
    }
  }

  floodFill(true);

  // Now perform flood fill starting from interior spaces
  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      if (layerMap[row][col] === -1 && labeledImageArray[row][col] === -1) {
        LabelSpaceOrBoundary(row, col, false, true);
      }
    }
  }
  floodFill(false);

  // All unlabeled nodes are solid non-boundary nodes
  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      if (labeledImageArray[row][col] === -1) {
        labeledImageArray[row][col] = SOLID_NON_BOUNDARY;
      }
    }
  }

  return labeledImageArray;
}

export const ComputeBorderDistanceMap = (layerMap, normFunction, insideOutsideMode) => { // insideOutsideMode can be 'inside', 'outside' or 'both'
  const minrow = 0;
  const mincol = 0;
  const maxrow = layerMap.length; // non-inclusive limit
  const maxcol = layerMap[0].length; // non-inclusive limit
  const distanceMap = layerMap.map(line => line.map(_ => -1)); // Initialized to -1, meaning that no distance value was calculated.

  const includeInside = insideOutsideMode === 'inside' || insideOutsideMode === 'both';
  const includeOutside = insideOutsideMode === 'outside' || insideOutsideMode === 'both';

  const searchQueue = new PriorityQueue((a, b) => {
    const difference = normFunction(b.row-b.startrow, b.col-b.startcol)-normFunction(a.row-a.startrow, a.col-a.startcol);
    if (Math.abs(difference) < 1e-7) {
      return 0;
    } else {
      return Math.sign(difference);
    }
  });

  function AddPixelNeighborsToQueue(row, col, startrow, startcol) {
    searchQueue.enqueue({row: row-1, col, startrow, startcol});
    searchQueue.enqueue({row: row+1, col, startrow, startcol});
    searchQueue.enqueue({row, col: col-1, startrow, startcol});
    searchQueue.enqueue({row, col: col+1, startrow, startcol});
  }

  const labeledBorders = labelBorders(layerMap);

  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      if ((includeInside && labeledBorders[row][col] === INTERIOR_BOUNDARY) ||
          (includeOutside && labeledBorders[row][col] === EXTERIOR_BOUNDARY)) {
        AddPixelNeighborsToQueue(row, col, row, col);
      }
    }
  }

  // The eventual result of the search will be a 2D array with points assigned distances from the closest edge point, under the selected norm.
  // Two norms that can be handy are the infinity-norm, for what a traditional rectangular wood frame looked like and the 2-norm, which will
  // be more suitable for a frame that hugs the boundaries of the shape (such as in the case of a heart shape)
  while (!searchQueue.isEmpty()) {
    const {row, col, startrow, startcol} = searchQueue.dequeue();
    if (row < 0 || row >= maxrow || col < 0 || col >= maxcol) {
      continue;
    }
    if (distanceMap[row][col] !== -1) {
      // Cell has already been processed
      continue;
    }

    if (layerMap[row][col] !== -1) {
      // Cell is not empty space
      continue;
    }

    distanceMap[row][col] = normFunction(row-startrow, col-startcol);
    AddPixelNeighborsToQueue(row, col, startrow, startcol);
  }

  return distanceMap;
}

const EuclideanNorm = (xPitch, yPitch) => (x, y) => Math.sqrt(x*xPitch*x*xPitch+y*yPitch*y*yPitch);
const InfinityNorm = (xPitch, yPitch) => (x, y) => Math.max(Math.abs(x*xPitch),Math.abs(y*yPitch));

export const getNormFunction = (normSpec, buildSettings) => {
  switch (normSpec.type) {
    case 'Euclidean':
      return EuclideanNorm(buildSettings.pitch.horizontalPitch, buildSettings.pitch.verticalPitch);
    case 'Infinity':
      return InfinityNorm(buildSettings.pitch.horizontalPitch, buildSettings.pitch.verticalPitch);
    default:
      throw new Error(`Invalid norm function: ${normSpec}`);
  }
}

export const GetBorderProfile = (inputImage) => {
  const image = inputImage.grayscale().contrast(1); // Turn into a monochrome image
  const borderProfileArray = [];
  const rowCount = image.bitmap.height;
  const colCount = image.bitmap.width;
  for (let col = 0; col < colCount; col++) {
    if (col !== 0) {
      if (image.getPixelColor(col, 0) !== image.getPixelColor(col-1, 0)) {
        throw new Error(`A border profile must have all top pixels of the same colour, but columns ${col-1} and ${col} have mismatching colours ${image.getPixelColor(col-1, 0)} and ${image.getPixelColor(col, 0)}.`);
      }
    }
    let row;
    for (row = 1; row < rowCount; row++) {
      if (image.getPixelColor(col, row) !== image.getPixelColor(col, row-1)) {
        break;
      }
    }
    borderProfileArray.push(rowCount-row);
  }

  const minProfileValue = Math.min(...borderProfileArray);
  const maxProfileValue = Math.max(...borderProfileArray);

  return borderProfileArray.map(element => (element - minProfileValue) / (maxProfileValue - minProfileValue));
}

export const computeBorderImageLayer = (layerMap, borderProfile, borderwidth, insideOutsideMode, normFunction) => {
  // Get distance map
  const distanceMap = ComputeBorderDistanceMap(layerMap, normFunction, insideOutsideMode);
  // For each point within the distance, assign point to border layer, and compute border height from distance
  const pixelArray = distanceMap.map(line => line.map(distance => distance > borderwidth || distance <= 0 ? 0 :
                                                      0xFF + 0x01010100 * Math.round(255 * (1-interpolate(borderProfile, 0, borderwidth, distance)))));
  const labeledConnectedComponents = pixelArray.map(line => line.map(pixelValue => pixelValue === 0 ? 1 : -1));
  return { pixelArray, labeledConnectedComponents };
}




export const applyBorder = (layerMap, depthMap, layerAreaBounds, hydratedBorder, buildSettings, targetLayerNumber, borderLayerNumber, containedLayerNumber) => {
  const borderProfile = hydratedBorder.profile;
  const borderWidth = hydratedBorder.width;
  const targetAreaBounds = layerAreaBounds.getBounds(targetLayerNumber);
  if (!targetAreaBounds) { // This can happen if an image in a parent layer has not been loaded yet.
    return;
  }

  const {minRow, minCol, maxRow, maxCol } = targetAreaBounds; // Inclusive limits
  const normFunction = getNormFunction({type: 'Euclidean'}, buildSettings);

  const searchQueue = new PriorityQueue((a, b) => {
    const difference = normFunction(b.row-b.startrow, b.col-b.startcol)-normFunction(a.row-a.startrow, a.col-a.startcol);
    if (Math.abs(difference) < 1e-7) {
      return 0;
    } else {
      return Math.sign(difference);
    }
  });

  const AddPixelNeighborsToQueue = (row, col, startrow, startcol) => {
    searchQueue.enqueue({row: row-1, col, startrow, startcol});
    searchQueue.enqueue({row: row+1, col, startrow, startcol});
    searchQueue.enqueue({row, col: col-1, startrow, startcol});
    searchQueue.enqueue({row, col: col+1, startrow, startcol});
  }
  
  const AddPixelToSearchStart = (row, col) => {
    searchQueue.enqueue({row, col, startrow: row, startcol: col});
  }

  // Perform search from all pixels that are not within the target layer number - those are either boundary pixels or all their neighbors will be ignored by the search function
  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      if (layerMap[row][col] !== targetLayerNumber) {
        AddPixelToSearchStart(row-1, col);
        AddPixelToSearchStart(row+1, col);
        AddPixelToSearchStart(row, col-1);
        AddPixelToSearchStart(row, col+1);
      }
    }
  }
  // Also initiate search from "pixels" that are directly adjancent to the boundary rectangle. This works even if those "pixels" are outside the bounds of the array because the
  // coordinates are not used for access without checking for bounds.
  for (let row = minRow; row <= maxRow; row++) {
    searchQueue.enqueue({row: row, col: minCol, startrow: row, startcol: minCol});
    searchQueue.enqueue({row: row, col: maxCol, startrow: row, startcol: maxCol});
  }
  for (let col = minCol; col <= maxCol; col++) {
    searchQueue.enqueue({row: minRow, col: col, startrow: minRow, startcol: col});
    searchQueue.enqueue({row: maxRow, col: col, startrow: maxRow, startcol: col});
  }

  let containedAreaBoundingRectangle;
  const updateBoundingRectangle = (row, col) => {
    let boundingMinRow = row, boundingMinCol = col, boundingMaxRow = row, boundingMaxCol = col;
    if (containedAreaBoundingRectangle) {
      ({ minRow: boundingMinRow, minCol: boundingMinCol, maxRow: boundingMaxRow, maxCol: boundingMaxCol } = containedAreaBoundingRectangle);
    }
    containedAreaBoundingRectangle = { minRow: Math.min(row, boundingMinRow), minCol: Math.min(col, boundingMinCol), maxRow: Math.max(row, boundingMaxRow), maxCol: Math.max(col, boundingMaxCol) };
  }

  while (!searchQueue.isEmpty()) {
    const {row, col, startrow, startcol} = searchQueue.dequeue();
    if (row < minRow || row > maxRow || col < minCol || col > maxCol) {
      continue;
    }

    if (layerMap[row][col] !== targetLayerNumber) {
      // Cell is not within area of interest or has already been processed
      continue;
    }

    const distance = normFunction(row-startrow, col-startcol);
    if (distance > borderWidth || distance < 0) {
      layerMap[row][col] = containedLayerNumber;
      updateBoundingRectangle(row, col);
    } else {
      layerMap[row][col] = borderLayerNumber;
      depthMap[row][col] = 1-interpolate(borderProfile, 0, borderWidth, distance);
    }

    AddPixelNeighborsToQueue(row, col, startrow, startcol);
  }

  layerAreaBounds.setBounds(borderLayerNumber, targetAreaBounds);
  layerAreaBounds.setBounds(containedLayerNumber, containedAreaBoundingRectangle);
}
