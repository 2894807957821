import { Button } from "@mui/material";
import { LIGHT_COLOR } from "luminopix-ui-common";

export const CustomImageButton = (props) => {
  return (
    <Button component="label" sx={{backgroundColor: "darkgreen", color: LIGHT_COLOR }}>
      Use your own image
      <input hidden type="file" onChange={(event) => {
        let selectedImageUrl = null;
        if (event?.target?.files?.length) {
          selectedImageUrl = URL.createObjectURL(event.target.files[0]);
          props.onSelect({
            downloadUrl: selectedImageUrl
          });
        }
        props.onSelect(null); // The user cancelled and no image was selected.
      }} />
    </Button>
  )
}