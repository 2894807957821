import React from 'react';
import { useGetBordersQuery } from 'luminopix-ui-common';
import { ProfileSelectionDialog } from '../ProfileSelectionDialog';
import { BorderPreview } from './BorderPreview';

export function BorderSelectionDialog(props) {
  const apiQueryResults = useGetBordersQuery();

  const getMedia = profile => (
    {
      mediaType: "3dview",
      element: (
          <BorderPreview profile={profile.borderProfile} />
        ),
      // TODO: The view above should be replaced with a precalculated thumbnail image.
      // src: profile.thumbnailUrl,
      // alt: `Preview of ${profile.name}`,
    }
  )

  return (
    <ProfileSelectionDialog
      dialogTitle="Select a frame profile"
      apiQueryResults={apiQueryResults}
      getProfileMedia={getMedia}
      getProfileName={profile => profile.name}
      onSelect={props.onSelect}
      dialogOpen={props.dialogOpen}
      setDialogOpen={props.setDialogOpen} />
  );
}