import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

export const layerSelectionSlice = createSlice({
  name: "layerSelection",
  initialState: {
    selectedLayerNumber: -1,
  },
  reducers: {
    resetSelectedLayerNumber: state => {
      state.selectedLayerNumber = -1;
    },
    toggleSelectedLayerNumber: (state, action) => {
      if (action.payload) {
        if (action.payload.layerNumber === state.selectedLayerNumber) {
          state.selectedLayerNumber = -1;
        } else {
          state.selectedLayerNumber = action.payload.layerNumber;
        }
      }
    },
  },
});

const selectSelectedLayerNumber = state => {
  return state.layerSelection.selectedLayerNumber;
}

export const useLayerSelector = () => {
  const selectedLayerNumber = useSelector(selectSelectedLayerNumber);
  const dispatch = useDispatch();
  const isSelected = layerNumber => selectedLayerNumber === layerNumber;
  const toggleSelectedLayerNumber = layerNumber => dispatch(layerSelectionSlice.actions.toggleSelectedLayerNumber({layerNumber}));
  const resetSelectedLayerNumber = () => dispatch(layerSelectionSlice.actions.resetSelectedLayerNumber());

  return Object.freeze({
    get selectedLayerNumber() { return selectedLayerNumber; },
    isSelected,
    toggleSelectedLayerNumber,
    resetSelectedLayerNumber,
  });
}

export default layerSelectionSlice.reducer;
