import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Link, List, ListItem, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import FilterIcon from '@mui/icons-material/Filter';
import HomeIcon from '@mui/icons-material/Home';
import FeedIcon from '@mui/icons-material/Feed';
import DownloadIcon from '@mui/icons-material/Download';
import { DownloadStlDialog } from './StlDownload/DownloadStlDialog';
import { TemplateSelectionDialog } from './TemplateSelectionDialog';
import { useBuildSettings } from '../redux/settings';
import { useHydratedPart } from '../LithophanePart/HydratedPartProvider';

export function ButtonDrawerView() {
  const [ templateAlertOpen, setTemplateAlertOpen ] = useState(false);
  const [ downloadDialogOpen, setDownloadDialogOpen ] = useState(false);
  const [ templatesDialogOpen, setTemplatesDialogOpen ] = useState(true);

  const { hydratedPart } = useHydratedPart();
  const buildSettings = useBuildSettings();

  return (
    <Box sx={{ backgroundColor: "darkseagreen", flexShrink: 0 }}>
      <List>
        <ListItem>
          <>
            <Tooltip title="Select template">
              <Button onClick={() => setTemplateAlertOpen(true)}>
                <FilterIcon />
              </Button>
            </Tooltip>
            <Dialog open={templateAlertOpen} onClose={() => setTemplateAlertOpen(false)}>
              <DialogTitle>All changes will be lost!</DialogTitle>
              <DialogContent><DialogContentText>Selecting a template will cause all work done so far to be irreversibly lost.</DialogContentText></DialogContent>
              <DialogActions>
                <Button autoFocus onClick={() => setTemplateAlertOpen(false)}>Cancel</Button>
                <Button onClick={() => { setTemplateAlertOpen(false); setTemplatesDialogOpen(true); }}>Select template</Button>
              </DialogActions>
            </Dialog>
            <TemplateSelectionDialog dialogOpen={templatesDialogOpen} setDialogOpen={setTemplatesDialogOpen} />
          </>
        </ListItem>
        <ListItem>
          <Tooltip title="Download STL">
            <Button onClick={() => setDownloadDialogOpen(true)}>
              <DownloadIcon />
            </Button>
          </Tooltip>
          <DownloadStlDialog dialogOpen={downloadDialogOpen} setDialogOpen={setDownloadDialogOpen} />
        </ListItem>
        <ListItem>
          <Tooltip title="We'd love your feedback">
            <Button component={Link} href="https://forms.gle/bahi7YQD7gy9cVtR8" target="_blank" rel="noopener">
              <FeedIcon />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem>
          <Tooltip title="Luminopix Home">
            <Button component={Link} href="https://www.luminopix.com/" target="_blank" rel="noopener">
              <HomeIcon />
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    </Box>
  )
}
