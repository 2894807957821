import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from "react";
import { Recomputable } from '../async/Recomputable';
import { BuildSettings } from './BuildSettings';
import { CompositeImage } from "./CompositeImage";
import { useHydratedPart } from './HydratedPartProvider';


const compositeImageContext = React.createContext();

export const CompositeImageProvider = (props) => {
  const [changeId, setChangeId] = useState(0);

  const { hydratedPart, changeId: hydratedPartChangeId } = useHydratedPart();
  const buildSettings = BuildSettings(0.2, 0.2);

  const [compositeImage] = useState(Recomputable(null));

  useEffect(() => {
    // Always recompute composite image if hydrated part is updated
    compositeImage.recompute(() => new CompositeImage(hydratedPart, buildSettings), () => setChangeId(nanoid()));
  }, [hydratedPartChangeId]);

  return <compositeImageContext.Provider value={{compositeImage: compositeImage.result, changeId}}>{props.children}</compositeImageContext.Provider>
}

export const useCompositeImage = () => {
  return React.useContext(compositeImageContext);
}
