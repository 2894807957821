import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form } from "formik";
import { useState } from "react";
import { BorderSelectionDialog } from "../Border/BorderSelectionDialog";
import { FixedPrecisionField } from "./FixedPrecisionField";
import { validateNumber } from "./validation";
import EditIcon from "@mui/icons-material/Edit"
import { LayerPhysicalPropertiesView } from "./LayerPhysicalPropertiesView";

export const BorderPropertiesView = (props) => {
  const border = props.border;
  const layer = props.layer;

  const [bordersDialogOpen, setBordersDialogOpen] = useState(false);

  return (
    <>
      <Box>
        <Button onClick={() => setBordersDialogOpen(true)}>
          Change profile <EditIcon />
        </Button>
        <BorderSelectionDialog
          dialogOpen={bordersDialogOpen}
          setDialogOpen={setBordersDialogOpen}
          onSelect={selectedBorder => border.update({profile: selectedBorder.borderProfile})}/>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={{
          width: border.width,
          borderProfile: border.profile,
        }}
        validate={ values => {
          const errors = {};
          let errorsFound = false;
          errorsFound |= !validateNumber(values.width, {min: 1, max: 100}, error => { errors.width = error })
          
          if (!errorsFound) {
            border.update(values);
          }

          return errors;
        }}
      >
        {formik => (
          <Form>
            <Stack sx={{width: 300}}>
              <FixedPrecisionField
                id="width"
                name="width"
                label="Width"
                unit="mm"
                onChange={value => { formik.setFieldValue("width", value) } }
                value={formik.values.width}
                error={formik.errors.width}
              />
            </Stack>
          </Form>
        )}
      </Formik>
      <LayerPhysicalPropertiesView layer={layer} />
    </>
  )
}
