import { Typography } from '@mui/material';
import React from 'react';
import { useGetStandsQuery } from 'luminopix-ui-common';
import { ProfileSelectionDialog } from '../ProfileSelectionDialog';
import { StandPreview } from './StandPreview';

export function StandSelectionDialog(props) {
  const apiQueryResults = useGetStandsQuery();

  const getMedia = profile => (
    {
      mediaType: "3dview",
      element: (
          <StandPreview profile={profile.standProfile} />
        ),
      // TODO: The view above should be replaced with a precalculated thumbnail image.
      // src: profile.thumbnailUrl,
      // alt: `Preview of ${profile.name}`,
    }
  )

  return (
    <ProfileSelectionDialog
      dialogTitle="Select a stand profile"
      apiQueryResults={apiQueryResults}
      getProfileMedia={getMedia}
      getProfileName={profile => profile.name}
      onSelect={props.onSelect}
      dialogOpen={props.dialogOpen}
      setDialogOpen={props.setDialogOpen} />
  );
}