export const DOWNLOAD_STATUS_NOT_STARTED = "not_started";
export const DOWNLOAD_STATUS_PREPARING = "preparing";
export const DOWNLOAD_STATUS_COMPLETED = "completed";
export const DOWNLOAD_STATUS_FAILED = "failed";

export const DownloadStatus = (status, additionalMessage) => {
  const DONE_COLOR = "green";
  const IN_PROGRESS_COLOR = "grey";
  const ERROR_COLOR = "red";

  let message;
  let color;
  switch (status) {
    case DOWNLOAD_STATUS_NOT_STARTED:
      message = "";
      color = IN_PROGRESS_COLOR;
      break;
    case DOWNLOAD_STATUS_PREPARING:
      message = "Creating STL...";
      color = IN_PROGRESS_COLOR;
      break;
    case DOWNLOAD_STATUS_COMPLETED:
      message = "Download completed";
      color = DONE_COLOR;
      break;
    case DOWNLOAD_STATUS_FAILED:
      if (additionalMessage) {
        message = `Download failed: ${additionalMessage}`;
      } else {
        message = `Download failed`;
      }
      color = ERROR_COLOR;
      break;
    default:
      throw new Error(`Invalid download status ${status}`);
  }

  return Object.freeze({
    status,
    message,
    color,
  });
}
