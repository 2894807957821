import { CancellationToken } from "./CancellationToken";

export const Recomputable = (initialValue) => {
  const idForTask = Date.now();
  console.log("Task ID:", idForTask);
  let inProgress = false;
  let isError = false;
  let error = null;
  let value = initialValue;
  let cancellationToken = null;
  let recomputePromise = null;

  let result = Object.freeze({
    get inProgress() { return inProgress },
    get isError() { return isError },
    get error() { return error },
    get value() { return value },
    get asyncValue() { return recomputePromise ?? new Promise(resolve => resolve(value)) },
  });

  const recompute = (asyncFunc, onChange, debounceDelayMs = 200) => {
    if (cancellationToken) {
      cancellationToken.cancel();
      console.log("Task Cancelled!", idForTask)
    }
    cancellationToken = CancellationToken();

    inProgress = true;
    isError = false;
    error = null;
    (async () => {
      console.log("Computation started for ", idForTask)
      const cancellationTokenAtStart = cancellationToken;
      try {
        // if (onChange) {
        //   onChange();
        // }
        await new Promise(resolve => {
          setTimeout(() => {
            resolve();
            console.log("Wait done!", idForTask)
          }, debounceDelayMs)
        });
        cancellationTokenAtStart.throwIfCancelled(); // Even if asyncFunc does not check the cancellationToken, this is an opportunity to avoid unnecessary computation that was already cancelled.
        console.log("Computation was not cancelled for ", idForTask)
        recomputePromise = asyncFunc(cancellationTokenAtStart);
        value = await recomputePromise;
        inProgress = false;
        isError = false;
        error = null;
        if (onChange) {
          onChange();
        }
      } catch (e) {
        if (!cancellationTokenAtStart.cancelled()) {
          console.log("Error! ", e)
          isError = true;
          inProgress = false;
          error = e;
        } else {
          console.log("Task was really cancelled!", idForTask)
        }
      } finally {
        // if (onChange) {
        //   onChange();
        // }
      }
    })();
  }

  return Object.freeze({
    result,
    recompute,
  });
}
