import { Box, Button } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLayerSelector } from "../../redux/layerSelection";
import { addChildLayer } from "../../redux/solid";
import { BorderSelectionDialog } from "../Border/BorderSelectionDialog";
import { ImageSelectorButton } from "../Image/ImageSelectorButton";
import { MaskSelectorButton } from "../Mask/MaskSelectorButton";

export const AddChildLayerActionView = props => {
  const parentLayer = props.layer;
  const parentAreaId = props.areaId;

  const dispatch = useDispatch();

  const layerSelector = useLayerSelector();

  const [ bordersDialogOpen, setBordersDialogOpen ] = useState(false);

  return (
    <Box>
      <Box>
        <Button onClick={() => { setBordersDialogOpen(true);}} >
          Add frame
        </Button>
      </Box>
      <Box>
        <ImageSelectorButton onSelect={selectedImage => addImage(selectedImage.locatingUrl, selectedImage.downloadUrl, false)}>
          Fill with image
        </ImageSelectorButton>
      </Box>
      <Box>
        <MaskSelectorButton onSelect={selectedMask => addImage(selectedMask.locatingUrl, selectedMask.downloadUrl, true)}>
          Add cut-out mask
        </MaskSelectorButton>
      </Box>
      <BorderSelectionDialog dialogOpen={bordersDialogOpen} setDialogOpen={setBordersDialogOpen} onSelect={selectedBorder => addBorder(selectedBorder)}/>
    </Box>
  );

  function addImage(locatingUrl, downloadUrl, isMask) {
    layerSelector.resetSelectedLayerNumber();
    const layerSpec = {
      id: nanoid(),
      type: isMask ? "mask" : "image",
      imageSpec: {
        locatingUrl,
        downloadUrl,
      },
    };

    if (!isMask) {
      layerSpec.minThickness = 0.8;
      layerSpec.maxThickness = 3;
    }

    addChildLayer(layerSpec, parentLayer.id, parentAreaId, dispatch);
  }

  function addBorder(selectedBorder) {
    if (selectedBorder) {
      layerSelector.resetSelectedLayerNumber();
      addChildLayer({
        id: nanoid(),
        type: "border",
        minThickness: 1.2,
        maxThickness: 5,
        borderSpec: {
          width: 10,
          profile: selectedBorder.borderProfile,
        },
      }, parentLayer.id, parentAreaId, dispatch);
    }
  }
}
