import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HydratedPartProvider } from './LithophanePart/HydratedPartProvider';
import { CompositeImageProvider } from './LithophanePart/CompositeImageProvider';
import { SolidFacesProvider } from './LithophanePart/SolidFacesProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PreselectTemplate } from './PreselectTemplate';
import EditorAppView from './EditorAppView';
import { CssBaseline } from '@mui/material';
import { Error404 } from 'luminopix-ui-common';
import { AnalyticsProvider } from './analytics';

const app = (
  <HydratedPartProvider>
    <CompositeImageProvider>
      <SolidFacesProvider>
      <EditorAppView />
      </SolidFacesProvider>
    </CompositeImageProvider>
  </HydratedPartProvider>
)

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={app} />
            <Route exact path="/template" element={<PreselectTemplate />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
