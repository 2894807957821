import { Box } from "@mui/material";
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { useLayerSelector } from "../../redux/layerSelection";
import { AddChildLayerActionView } from "./AddChildLayerActionView";
import { GlobalActionsView } from "./GlobalActionsView";

export const HierarchyActionView = () => {
  const { hydratedPart } = useHydratedPart();
  const layerSelector = useLayerSelector();

  const { layer: selectedLayer, areaId: selectedAreaId } = hydratedPart.getLayerAndAreaId(layerSelector.selectedLayerNumber);

  let hierarchyActionView;
  switch (selectedLayer?.type) {
    case 'border':
    case 'stand':
    case 'image':
    case 'mask':
      if (selectedAreaId > 0) {
        hierarchyActionView = <AddChildLayerActionView layer={selectedLayer} areaId={selectedAreaId} />
      } else {
        hierarchyActionView = <></>
      }
      break;
    case undefined:
      hierarchyActionView = <GlobalActionsView />
      break;
    default:
      hierarchyActionView = <></>
      break;
  }

  return (
    <Box sx={{width: 400}}>
      {hierarchyActionView}
    </Box>
  )
}