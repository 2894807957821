import { Box, Typography } from "@mui/material";

export const DownloadStatusBar = (props) => {
  const status = props.status;

  return (
    <Box sx={{display: "flex", flexDirection: "column", padding: "8px"}}>
      <Box sx={{justifyContent: "center"}}>
        <Typography color={status.color} align="center" variant="body1">{status.message}</Typography>
      </Box>
    </Box>
  )
}
