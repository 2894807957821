import Jimp from "jimp";
import Vertex from "../SolidGeometry/Vertex";
import { getLayerAndRelativeDepthMaps } from "./LayerMapComputation/LayerMap";

const scaleValue = (inValue, inMin, inMax, outMin, outMax) => {
  return (inValue-inMin)/(inMax-inMin)*(outMax-outMin) + outMin;
}

export class CompositeImage {
  constructor(hydratedPart, buildSettings) {
    this.hydratedPart = hydratedPart;
    this.buildSettings = buildSettings;
    this.boundary = {minX: 0, minY: 0, maxX: hydratedPart.dimensions.width, maxY: hydratedPart.dimensions.height};
    ({ layerMap: this.layerMap, depthMap: this.depthMap, layerAreaBounds: this.layerAreaBounds } = getLayerAndRelativeDepthMaps(buildSettings, this.boundary.maxX-this.boundary.minX, this.boundary.maxY-this.boundary.minY, hydratedPart));
  }

  coordinateToPixel = (x, y, rowCount, colCount) => {
    if (!this.boundary) {
      return null;
    }
    
    return {
      row: Math.round(scaleValue(y, this.boundary.minY, this.boundary.maxY, 0, rowCount)),
      col: Math.round(scaleValue(x, this.boundary.minX, this.boundary.maxX, 0, colCount)),
    };
  }

  getCenterPoint = () => {
    if (!this.boundary) {
      return new Vertex(0, 0, 0);
    }

    const {minX, minY, maxX, maxY} = this.boundary;
    return new Vertex((minX+maxX)/2, (minY+maxY)/2, 0);
  }

  getCenterNormal = () => {
    return new Vertex(1, 0, 0);
  }

  getCombinedGreyscaleImage = () => {
    if (!this.boundary) {
      return new Jimp(1, 1);
    }
    const width = this.depthMap[0].length;
    const height = this.depthMap.length;
    const image = new Jimp(width, height);
    for (let row = 0; row < height; row++) {
      for (let col = 0; col < width; col++) {
        const pixelColor = Math.round(this.depthMap[row][col] * 0xFF) * 0x01010100 + (this.layerMap[row][col] === -1 ? 0 : 0xFF)
        image.setPixelColor(pixelColor, col, row);
      }
    }
    return image;
  }

  getColorLabeledImage = (layerNumberToHighlight) => {
    if (!this.boundary) {
      return new Jimp(1, 1);
    }
    const width = this.depthMap[0].length;
    const height = this.depthMap.length;
    const image = new Jimp(width, height);
    for (let row = 0; row < height; row++) {
      for (let col = 0; col < width; col++) {
        let pixelColor;
        const { layerId, areaId } = this.hydratedPart.layerNumberMap.getLayerAndAreaId(this.layerMap[row][col]) || {};
        const layer = this.hydratedPart.getLayerById(layerId);
        if (!layer) {
          pixelColor = 0xFFCCCCFF; // Pink
        } else if (areaId > 0) {
          pixelColor = 0x00FF00FF; // Green
        } else if (layer.type === "mask") {
          pixelColor = 0x000000FF;
        } else {
          let depth = this.depthMap[row][col];
          let layerColor = 0xFFFFFFFF;
          const RED_MASK = 0xFF000000;
          const GREEN_MASK = 0x00FF0000;
          const BLUE_MASK = 0x0000FF00;
          const redComponent = (Math.round(((layerColor & RED_MASK) >>> 0) * depth) & RED_MASK) >>> 0;
          const greenComponent = Math.round((layerColor & GREEN_MASK) * depth) & GREEN_MASK;
          const blueComponent = Math.round((layerColor & BLUE_MASK) * depth) & BLUE_MASK;
          pixelColor = redComponent + greenComponent + blueComponent + 0xFF;
        }

        // Apply a pattern to the currently-selected layer
        if (layerNumberToHighlight !== -1 && this.layerMap[row][col] === layerNumberToHighlight) {
          if ((Math.round(50*row/height) ^ Math.round(50*col/width)) & 0x1) {
            pixelColor = (pixelColor ^ 0xFF00FF00) >>> 0;
          } else {
            pixelColor = (pixelColor ^ 0xFFFF0000) >>> 0;
          }
        }
        
        image.setPixelColor(pixelColor, col, row);
      }
    }
    return image;
  }

  getLayerIndexForOffsetRatio = (xOffsetRatio, yOffsetRatio) => {
    const col = Math.min(Math.floor(xOffsetRatio*this.layerMap[0].length), this.layerMap[0].length-1); // Cap value in case xOffsetRatio is 1.
    const row = Math.min(Math.floor(yOffsetRatio*this.layerMap.length), this.layerMap.length-1); // Cap value in case yOffsetRatio is 1.
    return this.layerMap[row][col];
  }
}
