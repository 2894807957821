import { Queue } from "buckets-js"

export const getConnectedComponentInfo = labeledImageArray => {
  // For each identified area, compute the first point (in row-major order) that is furthest from the the boundaries of the ... both horizontally and vertically.
  // The coordinates of this point expressed as 0 to 1 ratios will be used to start the traversal to find the extent of the area in a scaled version of the image.
  // This point can be used to map connected component ids in a stable way after scaling.

  const minrow = 0;
  const mincol = 0;
  const maxrow = labeledImageArray.length; // non-inclusive limit
  const maxcol = labeledImageArray[0].length; // non-inclusive limit

  // The arrays below are initialized by default to zero, indicating a zero margin (i.e. the pixel is at the edge of the area).
  const upMargin = Array.from({length: maxrow}, () => new Int16Array(maxcol));
  const downMargin = Array.from({length: maxrow}, () => new Int16Array(maxcol));
  const leftMargin = Array.from({length: maxrow}, () => new Int16Array(maxcol));
  const rightMargin = Array.from({length: maxrow}, () => new Int16Array(maxcol));

  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      if (row > 0 && labeledImageArray[row][col] === labeledImageArray[row-1][col]) {
        upMargin[row][col] = upMargin[row-1][col] + 1;
      }
      if (col > 0 && labeledImageArray[row][col] === labeledImageArray[row][col-1]) {
        leftMargin[row][col] = leftMargin[row][col-1] + 1;
      }
    }
  }

  for (let row = maxrow-1; row >= minrow; row--) {
    for (let col = maxcol-1; col >= mincol; col--) {
      if (row < maxrow-1 && labeledImageArray[row][col] === labeledImageArray[row+1][col]) {
        downMargin[row][col] = downMargin[row+1][col] + 1;
      }
      if (col < maxcol-1 && labeledImageArray[row][col] === labeledImageArray[row][col+1]) {
        rightMargin[row][col] = rightMargin[row][col+1] + 1;
      }
    }
  }

  const pixelBoundingRectangles = new Map();
  const updateBoundingRectangle = (areaId, row, col) => {
    const boundingRectangle = pixelBoundingRectangles.get(areaId);
    let boundingMinRow = row, boundingMinCol = col, boundingMaxRow = row, boundingMaxCol = col;
    if (boundingRectangle) {
      ({ minRow: boundingMinRow, minCol: boundingMinCol, maxRow: boundingMaxRow, maxCol: boundingMaxCol } = boundingRectangle);
    }
    pixelBoundingRectangles.set(areaId, { minRow: Math.min(row, boundingMinRow), minCol: Math.min(col, boundingMinCol), maxRow: Math.max(row, boundingMaxRow), maxCol: Math.max(col, boundingMaxCol) });
  }

  const maxMarginPoints = new Map();
  const margins = new Map();
  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      const areaId = labeledImageArray[row][col];
      const maxMarginSoFar = margins.get(areaId) ?? -1;
      const margin = Math.min(upMargin[row][col], downMargin[row][col], leftMargin[row][col], rightMargin[row][col]);
      if (margin > maxMarginSoFar) {
        margins.set(areaId, margin);
        maxMarginPoints.set(areaId, { rowRatio: row/maxrow, colRatio: col/maxcol });
      }
      updateBoundingRectangle(areaId, row, col);
    }
  }

  const boundingRectangles = new Map(); // Transform bounding rectangle coordinates to ratios between 0 and 1.
  for (let [areaId, rectangle] in pixelBoundingRectangles.entries()) {
    boundingRectangles.set(areaId, { minRow: rectangle.minRow/maxrow, minCol: rectangle.minCol/maxcol, maxRow: rectangle.maxRow/maxrow, maxCol: rectangle.maxCol/maxcol });
  }

  return { maxMarginPoints, boundingRectangles };
}

export const labelConnectedComponents = (jimpImage, layerId, layerNumberMap) => {
  const minrow = 0;
  const mincol = 0;
  const maxrow = jimpImage.bitmap.height; // non-inclusive limit
  const maxcol = jimpImage.bitmap.width; // non-inclusive limit
  const labeledImageArray = Array.from({length: maxrow}, () => new Int16Array(maxcol)); // Initialized to zeros by default. 0 means not yet labeled. Positive labels are for filled spaces. Negative labels are for transparent spaces.

  const floodFillQueue = new Queue();

  const addPixelNeighborsToQueue = (row, col) => {
    floodFillQueue.enqueue({row: row-1, col});
    floodFillQueue.enqueue({row: row+1, col});
    floodFillQueue.enqueue({row, col: col-1});
    floodFillQueue.enqueue({row, col: col+1});
  }

  const floodFill = (isTransparent, label) => {
    while (!floodFillQueue.isEmpty()) {
      const {row, col} = floodFillQueue.dequeue();
      if (row < 0 || row >= maxrow || col < 0 || col >= maxcol) {
        continue;
      }

      if (isTransparent ^ !(jimpImage.getPixelColor(col, row) & 0xFF)) {
        // Cell does not match what the flood fill is looking for.
        continue;
      }

      if (labeledImageArray[row][col] !== 0) {
        // Already processed
        continue;
      }

      labeledImageArray[row][col] = label;
      addPixelNeighborsToQueue(row, col);
    }
  }

  let nextTransparentOrdinal = 1;
  const opaqueLayerLabel = -1;
  for (let row = minrow; row < maxrow; row++) {
    for (let col = mincol; col < maxcol; col++) {
      if (labeledImageArray[row][col] === 0) {
        let label;
        const isTransparent = !(jimpImage.getPixelColor(col, row) & 0xFF);
        if (isTransparent) {
          layerNumberMap?.assignNextLayerNumber(layerId, nextTransparentOrdinal);
          label = nextTransparentOrdinal;
          nextTransparentOrdinal++;
        } else {
          label = opaqueLayerLabel;
        }
        floodFillQueue.enqueue({row, col});
        floodFill(isTransparent, label)
      }
    }
  }

  // In the result, the elements are ordered in their order of appearance in the array in a row-major traversal.
  // This layer numbers be consistent across runs because the code runs on the native resolution of the image.
  return { labeledImageArray };
}

export const labelAndMapConnectedComponents = (jimpImage, maxMarginPoints) => {
  const resizedLabeledImageArray = labelConnectedComponents(jimpImage).labeledImageArray;
  const resizedToOriginalAreaIds = new Map();
  for (let [areaId, { rowRatio, colRatio }] of maxMarginPoints.entries()) {
    const row = Math.floor(rowRatio * jimpImage.bitmap.height);
    const col = Math.floor(colRatio * jimpImage.bitmap.width);
    resizedToOriginalAreaIds.set(resizedLabeledImageArray[row][col], areaId);
  }

  // Replace area ids with what they have been in the original non-resized image. This way area ids remain stable regardless of resizing.
  for (let row = 0; row < resizedLabeledImageArray.length; row++) {
    for (let col = 0; col < resizedLabeledImageArray[0].length; col++) {
      resizedLabeledImageArray[row][col] = resizedToOriginalAreaIds.get(resizedLabeledImageArray[row][col]);
    }
  }

  return resizedLabeledImageArray;
}
