import Face from "./Face";
import Vertex from "./Vertex";

export class FaceCoordinatesCollection {
  constructor(layerNumberInMap) {
    this.coordinates = [];
    this.layerNumberInMap = layerNumberInMap;
  }
  
  addFaces(...faces) {
    for (let face of faces) {
      this.coordinates.push(...face.toFloat32Array());
    }
  }
  
  getFaceCount() {
    return this.coordinates.length / 9;
  }

  getFace(index) {
    const startCoordinate = index*9;
    const getVertex = coordinate => new Vertex(this.coordinates[coordinate], this.coordinates[coordinate+1], this.coordinates[coordinate+2]);
    return new Face(
      getVertex(startCoordinate),
      getVertex(startCoordinate+3),
      getVertex(startCoordinate+6),
    );
  }
}
