import { nanoid } from '@reduxjs/toolkit';
import { Queue } from 'buckets-js';
import Jimp from 'jimp';
import { getResourceById } from 'luminopix-ui-common';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Recomputable } from '../async/Recomputable';
import { replaceLayer, setSolidProperties as setSolidProperties, useSolid } from "../redux/solid";
import { getConnectedComponentInfo, labelConnectedComponents } from './LayerMapComputation/ImageSegmentation';

const LayerNumberMap = () => {
  let nextLayerNumber = 1;
  const layerNumberMap = new Map();

  const assignNextLayerNumber = (layerId, areaId) => {
    const preexistingLayerNumber = getLayerNumber(layerId, areaId);
    if (preexistingLayerNumber) {
      return preexistingLayerNumber;
    }
    const layerNumber = nextLayerNumber;
    layerNumberMap.set(layerNumber, { layerId, areaId });
    nextLayerNumber++;
    return layerNumber;
  }

  const getLayerNumbersByAreaId = layerId => {
    const resultMap = new Map();
    for (let [layerNumber, {layerId: mappedLayerId, areaId: mappedAreaId}] of layerNumberMap.entries()) {
      if (mappedLayerId === layerId) {
        resultMap.set(mappedAreaId, layerNumber);
      }
    }
    return resultMap;
  }

  const getLayerNumber = (layerId, areaId) => {
    for (let [layerNumber, {layerId: mappedLayerId, areaId: mappedAreaId}] of layerNumberMap.entries()) {
      if (mappedLayerId === layerId && mappedAreaId === areaId) {
        return layerNumber;
      }
    }
    return null;
  }

  return Object.freeze({
    assignNextLayerNumber,
    getLayerAndAreaId: layerNumber => layerNumberMap.get(layerNumber),
    getLayerNumbersByAreaId,
    getLayerNumber,
  })
}

const updateField = (targetObject, sourceObject, targetFieldName, sourceFieldName=targetFieldName) => {
  if (sourceObject[sourceFieldName] !== undefined && targetObject[targetFieldName] !== sourceObject[sourceFieldName]) {
    targetObject[targetFieldName] = sourceObject[sourceFieldName];
    return true;
  }
  return false;
}

const updatedNumberField = (targetObject, sourceObject, targetFieldName, sourceFieldName=targetFieldName) => {
  const newValue = Number(sourceObject[sourceFieldName]);
  if (!Number.isNaN(newValue) && targetObject[targetFieldName] !== newValue) {
    targetObject[targetFieldName] = newValue;
    return true;
  }
  return false;
}

const getImage = (imageSpec, originalImage, layerId, layerNumberMap, notifyChange, dispatchLayerUpdate) => {
  if (!imageSpec) {
    return null;
  }

  const opaqueLayerNumber = layerNumberMap.assignNextLayerNumber(layerId, -1);
  
  const reloadImage = image => {
    image.recompute(async () => {
      let imageDownloadUrl;
      if (imageSpec.downloadUrl) {
        imageDownloadUrl = imageSpec.downloadUrl;
      } else if (imageSpec.locatingUrl.startsWith("https://gallery.luminopix.com/") || imageSpec.locatingUrl.startsWith("https://depot.luminopix.com/")) {
        const locatingUrl = new URL(imageSpec.locatingUrl);
        const resourceType = locatingUrl.pathname.substring(1); // substring to remove the leading '/'
        if (resourceType !== "image" && resourceType !== "mask") {
          throw new Error(`The following URL is not a valid image locator URL ${imageSpec.locatingUrl}`);
        }
        const id = locatingUrl.searchParams.get("id");
        const resourceQueryResult = await getResourceById(resourceType, id);
        imageDownloadUrl = resourceQueryResult.downloadUrl;
      }
      const jimpImage = await Jimp.read(imageDownloadUrl);
      const base64Image = await jimpImage.getBase64Async(Jimp.MIME_PNG);
      const connectedComponents = labelConnectedComponents(jimpImage, layerId, layerNumberMap); // This computation uses the native image resolution for consistency.
      const { maxMarginPoints, boundingRectangles } = getConnectedComponentInfo(connectedComponents.labeledImageArray);
      return { imageDownloadUrl, jimpImage, base64Image, maxMarginPoints, boundingRectangles };
    }, notifyChange);
  }

  let image;
  if (originalImage && (originalImage?.downloadUrl === imageSpec.downloadUrl || originalImage?.locatingUrl === imageSpec.locatingUrl)) {
    image = originalImage.resourceLoader;
  } else {
    image = Recomputable(originalImage?.result ?? { jimpImage: new Jimp(1, 1) });
    reloadImage(image);
  }

  const update = (newValues) => {
    let updated = false;
    updated |= updateField(imageSpec, newValues, 'locatingUrl', 'locatingUrl');
    updated |= updateField(imageSpec, newValues, 'downloadUrl', 'downloadUrl');

    if (updated) {
      reloadImage(image);
      dispatchLayerUpdate();
    }
  }

  return Object.freeze({
    get resourceLoader() { return image },
    get locatingUrl() { return imageSpec.locatingUrl },
    get downloadUrl() { return image.result?.value?.imageDownloadUrl },
    update,
    opaqueLayerNumber,
  });
}

const getBorder = (borderSpec, layerId, layerNumberMap, dispatchLayerUpdate) => {
  if (!borderSpec) {
    return null;
  }

  const BORDER_AREA_ID = -1;
  const CONTAINED_AREA_ID = 1;
  const borderLayerNumber = layerNumberMap.assignNextLayerNumber(layerId, BORDER_AREA_ID);
  const containedLayerNumber = layerNumberMap.assignNextLayerNumber(layerId, CONTAINED_AREA_ID);

  return Object.freeze({
    get profile() { return borderSpec.profile },
    get width() { return borderSpec.width },
    update: (newValues) => {
      let updated = false;
      updated |= updatedNumberField(borderSpec, newValues, 'width');
      updated |= updateField(borderSpec, newValues, 'profile');

      if (updated) {
        dispatchLayerUpdate();
      }
    },
    get borderLayerNumber() { return borderLayerNumber },
    get containedLayerNumber() { return containedLayerNumber },
  });
}

const getStand = (standSpec, layerId, layerNumberMap, dispatchLayerUpdate) => {
  if (!standSpec) {
    return null;
  }

  const STAND_AREA_ID = -1;
  const CONTAINED_AREA_ID = 1;
  const standLayerNumber = layerNumberMap.assignNextLayerNumber(layerId, STAND_AREA_ID);
  const containedLayerNumber = layerNumberMap.assignNextLayerNumber(layerId, CONTAINED_AREA_ID);

  return Object.freeze({
    get outerHeight() { return standSpec.outerHeight },
    get innerRise() { return standSpec.innerRise },
    get sideSpread() { return standSpec.sideSpread },
    get profile() { return standSpec.profile },
    update: (newValues) => {
      let updated = false;
      updated |= updatedNumberField(standSpec, newValues, 'outerHeight');
      updated |= updatedNumberField(standSpec, newValues, 'innerRise');
      updated |= updatedNumberField(standSpec, newValues, 'sideSpread');
      updated |= updateField(standSpec, newValues, 'profile');

      if (updated) {
        dispatchLayerUpdate();
      }
    },
    get standLayerNumber() { return standLayerNumber },
    get containedLayerNumber() { return containedLayerNumber },
  });
}

const HydratedLayer = (layerSpec, layerNumberMap, notifyChange, dispatch, originalLayer = null) => {
  const dispatchLayerUpdate = () => dispatch(replaceLayer({layer: layerSpec}));

  const image = getImage(layerSpec.imageSpec, originalLayer?.image, layerSpec.id, layerNumberMap, notifyChange, dispatchLayerUpdate);
  const border = getBorder(layerSpec.borderSpec, layerSpec.id, layerNumberMap, dispatchLayerUpdate);
  const stand = getStand(layerSpec.standSpec, layerSpec.id, layerNumberMap, dispatchLayerUpdate);
  
  return Object.freeze({
    get type() { return layerSpec.type},
    get id() { return layerSpec.id },
    get parentLayerRef() { return Object.freeze(layerSpec.parentLayerRef) },
    get minThickness() { return layerSpec.minThickness },
    get maxThickness() { return layerSpec.maxThickness },
    get image() { return image },
    get border() { return border },
    get stand() { return stand },
    update: (newValues) => {
      let updated = false;
      updated |= updatedNumberField(layerSpec, newValues, 'minThickness');
      updated |= updatedNumberField(layerSpec, newValues, 'maxThickness');

      if (updated) {
        dispatchLayerUpdate();
      }
    },
  });
}

export const HydratedPart = () => {
  let hydratedLayers = [];
  let dimensions = { width: 100, height: 75 };
  let reverseSide = "flat";
  const layerNumberMap = LayerNumberMap();

  const hydrate = (partSpec, notifyChange, dispatch) => {
    // Rebuild the list of layers in the cache, preserving layers that do not need to be recomputed.
    const preUpdateHydratedLayers = hydratedLayers;
    hydratedLayers = [];
    for (let layerSpec of partSpec.layers) {
      const preUpdateLayer = preUpdateHydratedLayers.find(cacheLayer => cacheLayer.id === layerSpec.id);
      const newLayer = HydratedLayer(layerSpec, layerNumberMap, notifyChange, dispatch, preUpdateLayer);
      hydratedLayers.push(newLayer);
    }

    dimensions = JSON.parse(JSON.stringify(partSpec.dimensions));
    reverseSide = partSpec.reverseSide ?? "flat";
  }

  function* getLayersTopToBottom(topLayerId) {
    let topLayer;
    if (topLayerId) {
      topLayer = hydratedLayers.find(layer => layer.id === topLayerId);
      if (!topLayer) {
        throw new Error(`Layer id for top layer ${topLayerId} not found.`)
      }
    } else {
      topLayer = hydratedLayers.find(layer => !layer.parentLayerRef);
      if (!topLayer) {
        return;
      }
    }

    const traversalQueue = new Queue();
    traversalQueue.enqueue({layer: topLayer, parentLayer: null});
    while (!traversalQueue.isEmpty()) {
      const currentLayerInfo = traversalQueue.dequeue();
      const currentLayerIndex = hydratedLayers.findIndex(layer => layer.id === currentLayerInfo.layer.id);
      yield {...currentLayerInfo, layerIndex: currentLayerIndex};
      hydratedLayers.filter(layer => layer.parentLayerRef?.id === currentLayerInfo.layer.id).forEach(layer => traversalQueue.enqueue({layer, parentLayer: currentLayerInfo.layer}));
    }
  }

  return Object.freeze({
    get layers() { return hydratedLayers },
    get stand() { return hydratedLayers.find(layer => layer.type === "stand") },
    getLayer: id => hydratedLayers.find(layer => layer.id === id),
    hydrate,
    getTopLayer: () => hydratedLayers.find(layer => !layer.parentLayerRef),
    getLayersTopToBottom,
    getLayerById: layerId => hydratedLayers.find(layer => layer.id === layerId),
    getLayerAndAreaId: layerNumber => {
      const layerAndAreaId = layerNumberMap.getLayerAndAreaId(layerNumber);
      if (!layerAndAreaId) {
        return {};
      }
      const { layerId, areaId } = layerAndAreaId;
      const layer = hydratedLayers.find(layer => layer.id === layerId);
      return { layer, areaId };
    },
    layerNumberMap,
    get dimensions() { return { width: dimensions.width, height: dimensions.height } },
    get reverseSide() { return reverseSide ?? "flat" },
    update: (newValues, dispatch) => {
      dispatch(setSolidProperties({
        dimensions: {width: newValues.width, height: newValues.height},
        reverseSide: newValues.reverseSide,
      }));
    },
  });
}

const hydratedPartContext = React.createContext();

export const HydratedPartProvider = (props) => {
  const [changeId, setChangeId] = useState(0);
  const [hydratedPart] = useState(HydratedPart());

  const solid = useSolid();

  const dispatch = useDispatch();
  hydratedPart.hydrate(JSON.parse(JSON.stringify(solid)), () => setChangeId(nanoid()), dispatch);
  
  useEffect(() => {
    setChangeId(nanoid());
  }, [solid]);

  return <hydratedPartContext.Provider value={{hydratedPart, changeId}}>{props.children}</hydratedPartContext.Provider>
}

export const useHydratedPart = () => {
  return React.useContext(hydratedPartContext);
}
