import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Vertex from "../SolidGeometry/Vertex";
import { Box } from '@mui/material';
import { useSolidFaces } from "../LithophanePart/SolidFacesProvider";
import { useCompositeImage } from "../LithophanePart/CompositeImageProvider";
import { useLayerSelector } from "../redux/layerSelection";

function* getLightPositions() {
  for (let x = -1; x <= 1; x+=2) {
    for (let y = -1; y <= 1; y+=2) {
      for (let z = -1; z <= 1; z+=2) {
//        yield { x: x, y: 0, z: 0 };
        yield { x: 0, y: y, z: 0 };
        yield { x: 0, y: 0, z: z };
        yield { x: x, y: y, z: 0 };
        yield { x: 0, y: y, z: z };
        yield { x: x, y: 0, z: z };
      }
    }
  }
}

export default function SolidView() {
  const containerElement = useRef();
  const canvasParentElement = useRef();
  const spaceFillElement = useRef();

  const {compositeImage: compositeImageResult} = useCompositeImage();
  const compositeImage = compositeImageResult.value;
  const {coordinateCollections: coordinateCollectionsResult} = useSolidFaces();
  const coordinateCollections = coordinateCollectionsResult.value;

  const layerSelector = useLayerSelector();

  const [checkClickIntersectionsCallback, setCheckClickIntersectionsCallback] = useState(() => (pointer) => {});
  const onClick = (event) => {
    const pointer = new THREE.Vector2();
    const boundingRectangle = containerElement.current.getBoundingClientRect();
    const xPx = event.pageX - boundingRectangle.left;
    const yPx = event.pageY - boundingRectangle.top;
    const xOffsetRatio = xPx / (boundingRectangle.right - boundingRectangle.left);
    const yOffsetRatio = yPx / (boundingRectangle.bottom - boundingRectangle.top);
    pointer.x = xOffsetRatio * 2 - 1;
    pointer.y = -yOffsetRatio * 2 + 1;

    checkClickIntersectionsCallback(pointer);
  }

  useEffect(() => {
    THREE.Object3D.DefaultUp.set(0, 0, 1); // The default up axis is the y axis. Change that to the z axis.
  }, [])

  // The state variables below are overwritten in useEffect blocks, so that change takes effect immediately for latter code
  let [renderer, setRenderer] = useState(null);
  let [camera, setCamera] = useState(null);
  let [cameraPosition, setCameraPosition] = useState(null);
  let [isCameraPositionSet, setIsCameraPositionSet] = useState(false);
  let [controls, setControls] = useState(null);
  let [scene, setScene] = useState(null);
  
  const render = (scene, camera) => {
    renderer.render(scene, camera);
  }

  useEffect(() => { // Create renderer
    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setClearColor("#050525");
    renderer.setSize(containerElement.current.clientWidth, containerElement.current.clientHeight);
    setRenderer(renderer);
    canvasParentElement.current.appendChild(renderer.domElement);
    return () => {
      renderer.domElement.remove();
    }
  }, [containerElement, spaceFillElement]);

  useEffect(() => { // Create camera
    if (!cameraPosition) { // Once the camera position has been initialized once, do not reset the camera or controls.
      camera = new THREE.PerspectiveCamera(40, containerElement.current.clientWidth/containerElement.current.clientHeight, 0.001, 10000);
      controls = new OrbitControls(camera, renderer.domElement);
      controls.rotateSpeed = 3;
      controls.zoomSpeed = 2;
      cameraPosition = new Vertex(20, 3.75, 5);
      controls.target.set(5, 3.75, 0);
      controls.object.position.set(...cameraPosition.toFloat32Array());
      setCameraPosition(cameraPosition);
      setCamera(camera);
      setControls(controls);
    }
  }, [containerElement, spaceFillElement]);

  const resetCameraPosition = () => {
    cameraPosition = Vertex.add(compositeImage.getCenterPoint(), Vertex.scalarMultiply(50, compositeImage.getCenterNormal()));
    cameraPosition.z += (compositeImage.boundary.maxY - compositeImage.boundary.minY);
    cameraPosition.x *= 2;
    camera.position.x = cameraPosition.z;
    camera.position.y = cameraPosition.y;
    camera.position.z = cameraPosition.x;
    const centerPoint = compositeImage.getCenterPoint();
    controls.target.set(centerPoint.z, centerPoint.y, centerPoint.x);
    controls.object.position.set(...cameraPosition.toFloat32Array());
    controls.update();
  }

  useEffect(() => {
    if (compositeImage && compositeImage.boundary && coordinateCollections && !isCameraPositionSet) { // For now, the camera position is set only once after the first composite image is created.
      resetCameraPosition();
      setIsCameraPositionSet(true);
    }
  }, [containerElement, spaceFillElement, compositeImage, coordinateCollections]);

  useEffect(() => { // Create scene
    scene = new THREE.Scene();
    setScene(scene);

    const raycaster = new THREE.Raycaster();

    setCheckClickIntersectionsCallback(() => (pointer) => {
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      if (intersects?.[0]?.object?.layerNumberInMap) { // This is one of the meshes that are part of the lithphane.
        layerSelector.toggleSelectedLayerNumber(intersects[0].object.layerNumberInMap);
      } else {
        layerSelector.resetSelectedLayerNumber();
      }
    });

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries.length && containerElement.current) {
        renderer.setSize(containerElement.current.clientWidth, containerElement.current.clientHeight);
        camera.aspect = containerElement.current.clientWidth / containerElement.current.clientHeight;
        camera.updateProjectionMatrix();
        render(scene, camera);
      }
    });
    resizeObserver.observe(spaceFillElement.current);

    const getLight = (position) => {
      const light = new THREE.DirectionalLight("#ffffff", 0.1);
      light.position.set(position.x, position.y, position.z);
      return light;
    }

    [...getLightPositions()].forEach(position => scene.add(getLight(position)));

    
    const changeListenerCallback = () => {
      render(scene, camera);
    }
    controls.addEventListener("change", changeListenerCallback);
    controls.update();

    render(scene, camera);
    
    return () => {
      resizeObserver.unobserve(spaceFillElement.current);
      controls.removeEventListener("change", changeListenerCallback);
    }
  }, [containerElement, spaceFillElement])


  useEffect(() => {
    const removableItemsInScene = [];
    const addToScene = (item) => {
      scene.add(item);
      removableItemsInScene.push(item);      
    }

    const gridHelper = new THREE.GridHelper(1000, 100, "gray", "gray");
    gridHelper.rotateX(Math.PI / 2);
    addToScene(gridHelper);

    if (compositeImage && compositeImage.boundary && coordinateCollections) {
      for (const coordinateCollection of coordinateCollections) {
        const facesBuffer = Float32Array.from(coordinateCollection.coordinates);
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(facesBuffer, 3));
        geometry.computeVertexNormals();
        let color = 0xccc9ab;
        if (layerSelector.isSelected(coordinateCollection.layerNumberInMap)) {
          color = "pink";
        }
        const material = new THREE.MeshLambertMaterial( { color } );
        const mesh = new THREE.Mesh( geometry, material );
        mesh.layerNumberInMap = coordinateCollection.layerNumberInMap;
        addToScene(mesh);
      }
    }

    render(scene, camera);
    
    return () => {
      for (const item of removableItemsInScene) {
        scene.remove(item);
      }
    }
  }, [compositeImage, containerElement, spaceFillElement, coordinateCollections, layerSelector.selectedLayerNumber])


  return (
    <Box sx={{ flex:'1 0', display: "flex", flexDirection: "column", backgroundColor: "#050525" }}>
      <Box>
        {/* <Box sx={{width:300}}>
          <Slider aria-label="Preview quality" defaultValue={3} step={1} min={1} max={5} marks></Slider>
        </Box> */}
        {/* <Button><ZoomInIcon /></Button>
        <Button><ZoomOutIcon /></Button> */}
        {/* <Tooltip title="Download STL">
          <Button onClick={() => handleDownloadStl()}>
            <DownloadIcon />
          </Button>
        </Tooltip> */}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative' }} ref={containerElement}>
        <Box sx={{flex:'1 0 auto'}} ref={spaceFillElement} />
        <Box onClick={onClick} sx={{position: "absolute", top: 0, left: 0}} ref={canvasParentElement} />
      </Box>
    </Box>
  );
}
