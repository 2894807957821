import { Stack } from "@mui/material";
import { Formik, Form } from "formik";
import { FixedPrecisionField } from "./FixedPrecisionField";
import { MultipleChoiceField } from "./MultipleChoiceField";
import { validateNumber } from "./validation";

export const LayerPhysicalPropertiesView = (props) => {
  const layer = props.layer;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          minThickness: layer.minThickness,
          maxThickness: layer.maxThickness,
        }}
        validate={ values => {
          const errors = {};
          let errorsFound = false;
          errorsFound |= !validateNumber(values.minThickness, {min: 0.1, max: 50}, error => { errors.minThickness = error })
          errorsFound |= !validateNumber(values.maxThickness, {min: 0.1, max: 50}, error => { errors.maxThickness = error })
          
          if (!errorsFound) {
            layer.update(values);
          }

          return errors;
        }}
      >
        {formik => (
          <Form>
            <Stack sx={{width: 300}}>
              <FixedPrecisionField
                id="minThickness"
                name="minThickness"
                label="Minimum thickness"
                unit="mm"
                onChange={value => { formik.setFieldValue("minThickness", value) } }
                value={formik.values.minThickness}
                error={formik.errors.minThickness}
              />
              <FixedPrecisionField
                id="maxThickness"
                name="maxThickness"
                label="Maximum thickness"
                unit="mm"
                onChange={value => { formik.setFieldValue("maxThickness", value) } }
                value={formik.values.maxThickness}
                error={formik.errors.maxThickness}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  )  
}