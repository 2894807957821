import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { BuildSettings } from "../LithophanePart/BuildSettings";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    pitch: {
      horizontalPitch: 0.1,
      verticalPitch: 0.1,
    }
  },
  reducers: {
    updateSettings: (state, action) => {
      if (action.payload && action.payload.pitch) {
        state.pitch = action.payload.pitch;
      }
    },
  },
});

export const selectSettings = state => state.settings;
export const selectBuildSettings = state => BuildSettings(state.settings.pitch.horizontalPitch, state.settings.pitch.verticalPitch);

export const useBuildSettings = () => {
  return useSelector(selectBuildSettings);
}

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
