import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CancellationToken } from "../../async/CancellationToken";
import { CompositeImage } from "../../LithophanePart/CompositeImage";
import { useHydratedPart } from "../../LithophanePart/HydratedPartProvider";
import { selectSettings, updateSettings } from "../../redux/settings";
import { computeFacesForDisplay } from "../../SolidModel/LithophanePart";
import { StlWriter } from "../../SolidModel/StlWriter";
import { DownloadStatus, DOWNLOAD_STATUS_COMPLETED, DOWNLOAD_STATUS_FAILED, DOWNLOAD_STATUS_NOT_STARTED, DOWNLOAD_STATUS_PREPARING } from "./DownloadStatus";
import { DownloadStatusBar } from "./DownloadStatusBar";

export const DownloadStlDialog = (props) => {
  const dispatch = useDispatch();
  const { hydratedPart } = useHydratedPart();

  const persistedSettings = useSelector(selectSettings);
  const [modifiedSettings, setModifiedSettings] = useState(persistedSettings);
  const [dialogOpen, setDialogOpen] = [props.dialogOpen, props.setDialogOpen];

  const [cancellationToken] = useState(CancellationToken());
  const [downloadStatus, setDownloadStatus] = useState(DownloadStatus(DOWNLOAD_STATUS_NOT_STARTED));

  const blockDownload = downloadStatus === DOWNLOAD_STATUS_PREPARING;

  const closeDialog = () => {
    setDialogOpen(false);
    setDownloadStatus(DOWNLOAD_STATUS_NOT_STARTED);
  }

  const modifySetting = modificationFunction => event => {
    let newSettings = JSON.parse(JSON.stringify(modifiedSettings));
    modificationFunction(newSettings, event.target.value);
    setModifiedSettings(newSettings);
  }

  return (
    <Dialog open={dialogOpen} onClose={() => closeDialog()}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Resolution"
          margin="dense"
          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
          value={modifiedSettings.pitch.verticalPitch}
          onChange={modifySetting((settings, value) => { settings.pitch.verticalPitch = value; settings.pitch.horizontalPitch = value; })}
        />
        <DownloadStatusBar status={downloadStatus} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => closeDialog()}>Cancel</Button>
        <Button disabled={blockDownload} onClick={async () => {
          dispatch(updateSettings(modifiedSettings));
          let status = downloadStatus;
          await handleDownloadStl(hydratedPart, modifiedSettings, cancellationToken, (s, additionalMessage) => { status = s; console.log("Download status: ", s, additionalMessage); setDownloadStatus(DownloadStatus(s, additionalMessage))});
          if (status === DOWNLOAD_STATUS_COMPLETED) {
            closeDialog();
          }
        }}>Download</Button>
      </DialogActions>
    </Dialog>
  );

  async function handleDownloadStl(hydratedPart, buildSettings, cancellationToken, setDownloadStatus) {
    setDownloadStatus(DOWNLOAD_STATUS_PREPARING);
    if (!hydratedPart) {
      console.log("Cannot download STL...");
      setDownloadStatus(DOWNLOAD_STATUS_FAILED, "The object to download is still loading.");
      return;
    }
    console.log("Downloading STL...");

    const compositeImage = new CompositeImage(hydratedPart, buildSettings);
    if (cancellationToken.cancelled()) {
      setDownloadStatus(DOWNLOAD_STATUS_FAILED, "The download was cancelled.");
      return;
    }

    const faceCoordinatesCollections = await computeFacesForDisplay(compositeImage, cancellationToken);
    if (faceCoordinatesCollections.every(coordinateCollection => coordinateCollection.getFaceCount() === 0)) {
      setDownloadStatus(DOWNLOAD_STATUS_FAILED, "No object to download.");
      return;
    }

    if (cancellationToken.cancelled()) {
      setDownloadStatus(DOWNLOAD_STATUS_FAILED, "The download was cancelled.");
      return;
    }

    await new StlWriter().writeFile('model.stl', faceCoordinatesCollections);

    setDownloadStatus(DOWNLOAD_STATUS_COMPLETED);
  }
}
