import * as THREE from "three";

class Vertex {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    get_mirror_on_yz_plane() {
        return new Vertex(-this.x, this.y, this.z);
    }

    get_projection_on_yz_plane() {
        return new Vertex(0, this.y, this.z);
    }

    toVector3() {
        return new THREE.Vector3(this.x, this.y, this.z);
    }

    toFloat32Array() {
        return [this.x, this.y, this.z];
    }

    static add(vertex1, vertex2) {
        return new Vertex(vertex1.x+vertex2.x, vertex1.y+vertex2.y, vertex1.z+vertex2.z);
    }

    static scalarMultiply(scalar, vertex1) {
        return new Vertex(vertex1.x*scalar, vertex1.y*scalar, vertex1.z*scalar);
    }

    toString() {
        return (`(${this.x}, ${this.y}, ${this.z})`);
    }
}
        
export default Vertex;
