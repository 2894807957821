import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { BACKGROUND_COLOR } from 'luminopix-ui-common';
import React, { useEffect, useState } from 'react';
import Jimp from 'jimp';

export const CustomMaskDialog = (props) => {
  let [dialogOpen, setDialogOpen] = [props.dialogOpen, props.setDialogOpen];
  const closeDialog = () => {
    dialogOpen = false; // so that clicking a selection multiple times before the dialog closes does not result in calling props.onSelect
    setDialogOpen(false);
  }

  const [ imagePreviewSideLength, setImagePreviewSideLength ] = useState(0);
  const maskPreviewSideLength = imagePreviewSideLength * 2;

  const updateDimensions = () => {
    setImagePreviewSideLength(Math.min(window.innerHeight * 0.25, window.innerWidth * 0.2));
  }

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  });

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [maskPreviewBaseImage, setMaskPreviewBaseImage] = useState(null);
  const [previewMask, setPreviewMask] = useState(null);
  const [invertMask, setInvertMask] = useState(false);

  const computePreviewMask = async (image, doInvertMask) => {
    const monochromeImage = image.clone().greyscale().contrast(1);
    if (doInvertMask) {
      monochromeImage.invert();
    }
    
    const dataElementCount = 4 * monochromeImage.bitmap.width * monochromeImage.bitmap.height;
    for (let index = 0; index < dataElementCount; index++) {
      if (!(index & 0x1)) { // If this is the red or blue channel
        monochromeImage.bitmap.data[index] = 0; // Clear the channel
      }
    }

    setPreviewMask(await monochromeImage.getBase64Async(Jimp.MIME_PNG));
  }

  const computePreviewImage = async (url) => {
    const loadedJimpImage = await Jimp.read(url);
    const resizedImage = loadedJimpImage.clone().resize(maskPreviewSideLength, maskPreviewSideLength); // A mask will always be stretched to fill a square area for preview
    setMaskPreviewBaseImage(resizedImage);
    computePreviewMask(resizedImage, false)
  }

  const loadNewImage = async (url) => {
    setSelectedImageUrl(url);
    setInvertMask(false);
    computePreviewImage(url);
  }

  const getFinalMask = async () => {
    const loadedJimpImage = await Jimp.read(selectedImageUrl);
    const monochromeImage = loadedJimpImage.greyscale().contrast(1);
    if (invertMask) {
      monochromeImage.invert();
    }
    
    const dataElementCount = 4 * monochromeImage.bitmap.width * monochromeImage.bitmap.height;
    for (let index = 3; index < dataElementCount; index += 4) { // Start and step values chosen to loop only over alpha values
      if (monochromeImage.bitmap.data[index - 2] > 127) {
        monochromeImage.bitmap.data[index] = 0; // Make pixel transparent
      } else {
        monochromeImage.bitmap.data[index] = 255; // Make pixel opaque
      }
    }

    return await monochromeImage.getBase64Async(Jimp.MIME_PNG);
  }

  const confirmMaskSelection = async () => {
    if (dialogOpen) {
      closeDialog();
      const finalMask = await getFinalMask();
      props.onSelect({
        downloadUrl: finalMask,
      });
    }
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="xl">
      <DialogTitle>
        Custom mask
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">Click "Browse" and find an image of only two colors (dark and light, ideally black and white).</Typography>
        <Box sx={{ display: "flex", flexDirection:"row" }}>
          <Box>
            <Button component="label">
              Browse
              <input hidden type="file" onChange={async (event) => {
                let selectedMaskUrl = null;
                if (event?.target?.files?.length) {
                  selectedMaskUrl = URL.createObjectURL(event.target.files[0]);
                  await loadNewImage(selectedMaskUrl);
                }
              }} />
            </Button>
            <Box sx={{
              width: `${imagePreviewSideLength}px`,
              height: `${imagePreviewSideLength}px`,
              margin: "8px",
              padding: "0",
              borderColor: BACKGROUND_COLOR,
              borderWidth: "2px",
              borderStyle: "solid",
              justifyContent: "center",
            }}
            >
              { selectedImageUrl ? <img src={selectedImageUrl} style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: `${imagePreviewSideLength}px`,
                height: `${imagePreviewSideLength}px`,
                objectFit: "contain",
              }} /> : null }
            </Box>
          </Box>
          <Box>
            <Button disabled={!maskPreviewBaseImage} onClick={() => {
              setInvertMask(!invertMask);
              computePreviewMask(maskPreviewBaseImage, !invertMask);
            }}>Invert mask</Button>
            <Box sx={{
              width: `${maskPreviewSideLength}px`,
              height: `${maskPreviewSideLength}px`,
              margin: "8px",
              padding: "0",
              borderColor: BACKGROUND_COLOR,
              borderWidth: "2px",
              borderStyle: "solid",
              justifyContent: "center",
            }}
            >
              { previewMask ? <img src={previewMask} style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: `${maskPreviewSideLength}px`,
                height: `${maskPreviewSideLength}px`,
                objectFit: "contain",
              }} /> : null }
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button autoFocus onClick={closeDialog}>Cancel</Button>
      <Button onClick={async () => {
        await confirmMaskSelection(previewMask);
      }}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}