import { Navigate, useSearchParams } from "react-router-dom"
import { useGetTemplatesQuery } from "luminopix-ui-common";

export const PreselectTemplate = () => {
  const [urlParams] = useSearchParams();
  const templateId = urlParams.get("id");
  const {
    data: templates,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetTemplatesQuery();
  const template = templates?.find(template => template.id === templateId);
  
  if (template) {
    window.template = template;

    return (
      <Navigate to="/" />
    )
  } else if (isLoading) {
    return (
      <p>Loading template...</p>
    )
  } else {
    return (
      <>
        <h1>The template was not found.</h1>
        <a href="/">Launch Luminopix Studio without using a template</a>
      </>
    )
  }
}
