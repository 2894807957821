import React, { useEffect, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { interpolate } from "../../math/interpolate";

const Camera = () => {
  const cameraRef = useRef();
  const setThree = useThree(state => state.set);

  useEffect(() => {
    setThree({ camera: cameraRef.current });
  }, []);

  useThree(({camera}) => {
    camera.up.set(0, 0, 1)
    camera.lookAt(1, 1, 0)
    camera.zoom = 0.8
    camera.updateProjectionMatrix();
  })

  return (
    <orthographicCamera position={[-10, -10, 10]} ref={cameraRef}/>
  );
}

export const BorderPreview = (props) => {
  const borderProfile = props.profile;
  const minHeight = 0.25;
  const maxHeight = 0.8;
  const sliceCount = 128;
  const sliceHeights = [];
  for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
    sliceHeights.push(minHeight + (maxHeight - minHeight) * interpolate(borderProfile, 0, sliceCount-1, sliceIndex));
  }
  const sliceWidth = 1/sliceCount;
  const sliceLength = 5;

  return (
    <>
      <div style={{backgroundColor:"#123456", maxWidth: '100%', maxHeight: '100%'}}>
        <Canvas>
          <Camera />
          <pointLight position={[10, 10, 10]} />
          <pointLight position={[10, 10, -10]} />
          <pointLight position={[-10, 10, 10]} />
          {sliceHeights.map((height, index) => (
            <mesh key={`slice-${index}`} position={[sliceWidth*(index+0.5), sliceLength/2, height/2]}>
              <boxGeometry args={[sliceWidth, sliceLength, height]} />
              <meshStandardMaterial color='orange' />
            </mesh>
          ))}
        </Canvas>
      </div>
    </>
  );
}
